/* 
    VARIABLES
    -backgrounds
    -fonts
    -font-weight
    -font-sizes
    -borders
    -border-radius
    -border-sizes
    -widths
    -min-widths
    -max-widths
    -heights
    -min-heights
    -max-heights
    -box-shadows
*/


$default-border: 1px solid $gray-light;
$no-border: 0;

$default-border-radius: 4px;
$no-border-radius: 0px;

$card-shadow: 0 2px 10px 0 rgba(171,171,171,0.5);
$card-shadow-sm: 0 2px 4px 0 rgba(174,174,174,0.5);
$no-shadow: 0 0 0 0;

$oxygen-font-family: 'Oxygen', sans-serif;
$lato-font-family: 'Lato', sans-serif !important;

$primary-font-family: $lato-font-family;
$secondary-font-family: $oxygen-font-family;

$h1-size: 32px;
$h2-size: 24px;
$h3-size: 20px;
$h4-size: 16px;
$h5-size: 16px;
$h6-size: 16px;

$body-size: 16px;
$small-body-size: 14px;
$smallest-body-size: 14px;

/* @media (max-width: 769px){
    $h1-size: 26px;
    $h2-size: 22px;
    $h3-size: 18px;
    $h4-size: 16px;
    $h5-size: 16px;
    $h6-size: 14px;

    $body-size: 16px;
    $small-body-size: 14px;
    $smallest-body-size: 14px;
} */

$bold-weight: 700;
$regular-weight: 400;
$light-weight: 300;

$bold: $bold-weight;
$regular: $regular-weight; 
$light: $light-weight; 

/* Navigation Variables*/

$nav-bg: $light-bg;
$nav-active-bg: $secondary-medium;
$nav-hover-bg: $secondary-medium;
$nav-hover-color: $secondary-medium;
$nav-dropdown-hover-bg: $secondary-medium;
$nav-dropdown-hover-color: $white;
$nav-shadow: 0 2px 10px 0 rgba(171,171,171,0.5);
$nav-item-color: #4A4A4A;
$nav-separator-color: #4A4A4A;
$search-nav-bg: #F0EFFF;
$nav-toggler-bg: $secondary-medium;
$nav-toggler-color: $white;
$mobile-nav-border-color: $gray-light;
$upgrade-bg: $accent-dark;
$upgrade-color: $white;

/* SURGEON CARD VARIABLES */

$surgeon-card-bg: $white;
$surgeon-card-border: 1px solid $gray-light;
$surgeon-card-border-radius: 4px; 


/* LINE INPUT VARIABLE */

$line-form-border:  2px solid #DADADA;
$select-box-shadow: 2px -4px 0px -2px #DADADA inset;
$line-form-line-color: #DADADA;
$line-form-bg: transparent;
$line-form-font-family: $primary-font-family;
$line-form-label-font-family: $secondary-font-family;
$line-form-font-size: 16px;
$line-form-active-font-size: 13px;

    /* VALIDATIONs */
    $line-form-success-color: $success;
    $line-form-warning-color: $warning;
    $line-form-error-color: $error;
    $line-form-border-success:  2px solid $line-form-success-color;
    $line-form-border-warning:  2px solid $line-form-warning-color;
    $line-form-border-error:  2px solid $line-form-error-color;

/* TEXT BOXES VARIABLE */

$textbox-form-border:  2px solid #DADADA;
$textbox-form-bg: $white;
$textbox-form-font-family: $primary-font-family;
$textbox-form-label-font-family: $secondary-font-family;
$textbox-form-font-size: 16px; 

    /* VALIDATIONs */
    $textbox-form-success-color: $success;
    $textbox-form-warning-color: $warning;
    $textbox-form-error-color: $error;
    $textbox-form-border-success:  2px solid $textbox-form-success-color;
    $textbox-form-border-warning:  2px solid $textbox-form-warning-color;
    $textbox-form-border-error:  2px solid $textbox-form-error-color;


/* POPOVER VARIABLEs */

$popover-item-card-bg: $white;
$popover-item-card-shadow: 0 2px 10px 0 rgba(171,171,171,0.5);
$popover-item-card-title-color: $text-color-dark;
$popover-item-card-title-family: $lato-font-family;
$popover-item-card-description-color: $text-color;
$popover-item-card-description-family: $oxygen-font-family;
