/* CONTAINERS

    -card
    -media-card
    -surgeon-card
    -specialty-span
    -media-card 
    -icon
    -tabs
    ///MORE TO BE ADDED

*/ 
/* SHADOW */
.shadow-sm{
    box-shadow: $card-shadow-sm;
}

/* SURGEON CARD */
.surgeon-card{
    padding: 20px;
    transition: box-shadow 0.2s;
    .no-underline{ 
        &:hover{
            text-decoration: none !important;
        }
    }
    &{
        border: $surgeon-card-border !important;
        border-radius: $surgeon-card-border-radius !important;
        background: $surgeon-card-bg !important;
        box-shadow: $no-shadow; 
    }
    &:hover{
        cursor: pointer;
        box-shadow: 0 2px 10px 0 rgba(171,171,171,0.5);
    }
    .surgeon-card-body{
        display: flex;
        align-items: center;
        margin-bottom: 20px; 
        max-height: 100px;
    }
    .center-profile{
        flex-direction: column;
        .name-holder{
            width: 100%;
            padding-top: 12px;
            .surgeon-title{
                display: none;
            }
        }
    }
    .card-body{        
        border-radius: $surgeon-card-border-radius !important;
        background: $surgeon-card-bg !important;
        padding:0px;
    }
    .surgeon-image{
        border-radius: 50%;
        background: $gray-medium;
        margin-right: 12px; 
    }
    .surgeon-name{
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 8px;
        font-family: $lato-font-family;
    }
    .surgeon-title{
        font-weight: normal;
        font-size: 20px;
        font-family: $lato-font-family;
    }
    .surgeon-details{
        &{
            font-size: 16px; 
            margin-bottom: 12px;
        }
        &.with-btm-border{
            border-bottom: 1px solid #aaaaaa;
        }
        &:last-child{
            margin-bottom: 10px;
        }
        .item{
            &{
                margin-bottom: 8px;
            }
            &.skip{
                margin-bottom: 16px;
            }
            &:last-child{
                margin-bottom: 12px;
            }
            .item-detail{
                margin-right:40px;
            }
        }
    }
}

/* SURGEON INFO CARD */
.surgeon-info-card{

    box-shadow: $no-shadow;
    border-radius: 2px;
    border: $default-border;
    padding: 20px;

    .profile{
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
        img{
            margin-bottom: 10px;
        }
        .surgeon-name{
            margin-bottom: 8px;
        }
        .surgeon-job{
            margin-bottom: 8px;
        }
        .surgeon-contact{
            margin-bottom: 10px;
        }
    }
    .detail{
        .list{
            li{
                margin-bottom: 8px;
                &.item{
                    margin-bottom: 16px;
                }
            }
            
        }
    }
}

/* MEDIA CARD */

.media-card{
    &{
        background: $card-background !important;
        box-shadow: $no-shadow;
        border: $default-border !important;
        padding: 0px;
    }
    .media-image-container{
        padding-top: 66.67%;
        position: relative;
        overflow: hidden;
    }
    .media-image{
        position: absolute;
        background: $gray-dark;
        width: 100%;
        top: 0;
    }
    .media-title{
        height: 52px;
        color: $text-color;
        font-size: 16px;
        font-family: Lato;
        line-height: 24px;
        font-weight: bold;
        padding: 14px 10px;
    }
}

/* RECENT MEDIA CARD */
.recent-media-card{
    &{
        box-shadow: $no-shadow;
        border: $default-border;
        padding: 20px;
    }
    .recent-media-title{
        display: flex;
        align-items: center;
        margin-bottom: 8px;
    }
}

/* MY RECORDS CARD */
.my-records-card{
    &{
        box-shadow: $no-shadow;
        border: $default-border;
        padding: 20px;
        margin-bottom: 24px;
    }
    .my-records-title{
        margin-bottom: 8px;
        line-height: 28px;
        height: 28px;
    }
    .my-records-tile{ 
        &{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
            font-size: 32px;
            line-height: 40px;
        }

        &:nth-last-child(-n+2){
            margin-bottom: 0px;
        }

        .my-records-value{ 
            text-align: center;
        }
        .my-records-desc{
            text-align: center;
            font-size: $h3-size;
            font-family: $lato-font-family;
        }
    }
}

/* SAVED DRAFTS CARD */
.saved-drafts-card{
    
    .saved-drafts-title{
        margin-bottom: 8px;
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .saved-drafts-list{
        .item{
            &{
                padding: 12px 20px;
                border-bottom: 1px solid #e3e3e3;
                font-size: $body-size;
                display: flex;
                flex-direction: column;
            }
            &:first-child{
                padding-top: 0px;
                padding-bottom: 12px;
            }
            &:last-child{
                padding-top: 12px;
                padding-bottom: 0px;
                border-bottom: 0px;
            }
            .saved-drafts-date{
                margin-top: 12px;
                font-size: $small-body-size;
                font-weight: $light-weight;
            }
        }
    } 
    

}

/* ITEM PREVIEW CARD */
.item-preview-sm-card{
    
    padding: 0px;
    margin-bottom: 20px;
    /* height: calc(100% - 20px); */

    .item-img{
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    .item-body{
        
        padding: 20px;

        .item-name{
            margin-bottom: 16px;
        }
        .item-detail{
            
            text-align: center;

            &:not(:last-child){
                margin-bottom: 16px;
            }
            &.light{
                font-weight: $light-weight;
            }
        }
    }

}

/* MEDICAL INVENTORY CARD */
.medical-inventory-card{
    padding: 0px;
    height: 100%;
    border-radius:4px; 
    .item-img{
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    .item-body{
        padding: 20px;
        display: flex;
        flex-direction: column;

        .item-name{
            margin-bottom: 20px;
        }
        .item-description{
            margin-bottom: 64px;
        }
        .cta{
            align-self: end;
        }
    }
}

/* NOTIFICATION CARD */
.notification-card{

    border: $default-border;
    box-shadow: $no-shadow;
    border-radius: 0;
    padding: 20px;
    margin-bottom: 24px;
    
    .notification-body{ 
        display:flex; 

        & > img{
            margin-right:20px;
        } 
        .detail{
            display: flex;
            flex-direction: column;
            width: 100%;

            .notification-title, .notification-desc{
                margin-bottom: 8px;
                .cta{
                    display: unset;
                    margin: 0;
                } 
            }
            .notification-date{
                font-size: $small-body-size;
                font-weight: $light-weight;
                justify-self: end; 
                align-self: end;
                margin-left: auto;
            }
        }
    }
}

/* ICON */

.icon-sm{
    width: 28px;
    height: 28px;
}
.icon-s{
    width: 32px;
    height: 32px;
}
.icon-md{
    width: 44px;
    height: 44px;
}
.icon-lg{
    width: 52px;
    height: 52px;
}
.icon-xl{
    width: 100px;
    height: 100px;
}

.icon-circle{
    border-radius: 50%;
    &.icon-circle-sm{
        width: 50px;
        height: 50px;
    }
    &.icon-circle-lg{
        width: 100px;
        height: 100px;
    }
}




/* TABS */

.custom-tabs {
    &.nav-tabs{
        border-bottom: 1px solid $gray-light;
    }
    .nav-item:not(:first-child){
        margin: 0px 12px;
    }
    .nav-item:first-child{
        margin-right: 12px;
    }
    .nav-link{
        font-size: 16px;
        background: none !important;
        border: 0px;
        border-radius: 0px;
        padding:4px 0px;
        color: $primary-medium;
        margin-bottom: -1px;
        &.active, &:hover{
            border-bottom: 0px $secondary-medium !important;
            box-shadow: 0px -3px 0px -1px $secondary-medium inset !important;
            text-decoration: none !important;
            color: $secondary-medium;
        } 
    } 
}


/* SPECIALTY SPAN */

.specialty-span, 
.specialty-pill,
.tags-pill {
    font-size: 16px; 
    padding: 0px 12px;
    background: $primary-medium;
    border-radius: 17px;
    margin-right: 12px;
    color: $text-color-light !important;
    float: left;
    margin-top: 12px;
    height: 32px;
    display: flex;
    align-items: center;
}
.tags-pill{
    margin-top: 8px; 
}
td, th{
    .specialty-span{
        margin-top: 0px !important;
    }
}

/* TABLE */
/* ON MODAL TABLE mAX HEIGHT is 350px */

$table-thead-bg: $primary-medium;
$table-thead-color: $white;
$table-thead-font-size: 16px;
$table-thead-height: 42px;
$table-thead-font-family: $lato-font-family;
$table-thead-border: 1px solid $primary-medium;

$table-body-bg: $white;
$table-body-border: 1px solid $gray-light;
$table-body-color: $text-color;
$table-body-action-color: $primary-medium;
$table-body-hover-color: $secondary-medium;
$table-body-font-size: 14px;
$table-body-height: 42px; 
$table-body-font-family: $oxygen-font-family;
$table-body-hover-bg: $primary-light;
$table-form-border-color: #9f9f9f;
.corh-table{
    overflow: auto;
    .sortable-thead:hover{
        cursor: pointer;
    }
    table{
        width: 100%;
        thead{
            background: $table-thead-bg;
            border-top: $table-thead-border;
            border-left: $table-thead-border;
            border-right: $table-thead-border;
            border-bottom: $table-thead-border;
            tr{
                td,th{
                    min-height: $table-thead-height;
                    color: $table-thead-color;
                    font-family: $table-thead-font-family; 
                    border: $no-border;
                    font-size: $table-thead-font-size;
                    padding: 10px 8px;  
                    line-height: 22px;
                    &:first-child{
                        padding-left: 16px; 
                    }
                    &:last-child{
                        padding-right: 16px; 
                    }
                    i{
                        color: $table-thead-color;
                        margin-left: 8px;
                    }
                }
            }
        }
        tbody{ 
            background: $table-body-bg;
            border: $table-body-border;
            width: calc(100% + 1px);
            tr{
                border-top: $table-body-border;
                transition: .1s all;
                background: $table-body-bg;
                &:first-child{
                    border-top: $no-border;
                }
                &:hover, &.active, &.selected{
                    background: $table-body-hover-bg;
                    td:hover, td:hover *{
                        color: $table-body-hover-color;
                    }
                }
                td,th{
                    min-height: $table-body-height;
                    color: $table-body-color;
                    font-family: $table-body-font-family;
                    font-size: $table-body-font-size;
                    padding: 10px 8px;  
                    line-height: 22px;
                    &:first-child{
                        padding-left: 16px; 
                    }
                    &:last-child{
                        padding-right: 16px; 
                    }
                    &.has-action{
                        color: $table-body-action-color;
                        &:hover{
                            cursor: pointer;
                        }
                    }
                    &.actions{
                        font-weight: normal !important;
                        *{
                            font-weight: normal !important;
                        }
                    }
                    .custom-radio{
                        margin-right: 0px;
                        vertical-align: middle;
                        margin-left: -5px;

                        & .custom-control-label::after, 
                        & .custom-control-label::before{
                            left: -20px;
                        }
                    }
                    .custom-checkbox{
                        margin-right: 0px;
                        margin-left: -5px;
                        vertical-align: middle;

                        & .custom-control-label::after, 
                        & .custom-control-label::before{
                            left: -20px;
                        }
                    }
                    .table-form{
                        margin: 0px;

                        .form-control{
                            border: 1px solid $table-form-border-color;
                            border-radius: 4px;
                            height: 32px;
                            line-height: 32px;
                        }
                    } 
                }
            }
        }
    }
}

$infi-bg: #f4f4f4;
.infi-table{
    height: 648px;
    position: relative;
    overflow-y: hidden !important;
    overflow-x: auto !important;
    background: $infi-bg;
    box-shadow: 0 2px 4px 0 rgba(171,171,171,0.5);
    .infinite-scroll-component {
        padding-bottom: 32px
    }
    &::after{
        content:'';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100px;
        z-index: 3;
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #F9F9F9 100%);
    }
    table{
        z-index: 2;
        min-height: 350px;
        max-height: 350px; 
        position: absolute;
        td,th{
            padding: 8px 12px !important;
            .specialty-span{
                margin:0px !important;
                height: 27px;
            }
            &:last-child{
                padding-right: 12px;
            }
        }
        tbody{
            max-height: 309px;
            overflow: auto; 
            display: flex;
            flex-direction: column;
            &.infinite-tbody{
                max-height: 648px;  
                overflow-y: hidden !important;
                overflow-x: auto !important;
                background: $infi-bg;
            }
            tr:nth-last-of-type(1):not(.table-notification){
                box-shadow: 0 2px 4px 0 rgba(171,171,171,0.5); 
            }
        } 
        tr{
            display: inline-table;
            width: 100%;
        }
        thead {
            tr{
                width:  calc(100% - 10px);
            }
        }
    }
    &.with-checkbox, &.with-radio{
        td:first-child, th:first-child{
            padding-right: 0px !important;
            text-align: center !important;
        }
        td:only-child, th:only-child{
            padding-right: 12px;
        }
    }
}

/* COLLAPSIBLE DIV */
.single-collapse{

    border: 1px solid #DBDBDB;	
    border-radius: 4px;	
    background-color: #FFFFFF;
    .header{
        display: flex;
        align-items: center;
        padding: 16px;
        &:hover{
            cursor: pointer;
        }
        .title{
            font-size: 20px;
            font-weight: bold;
            font-family: $lato-font-family;
            color: $primary-medium;
        }
        .toggle-icon{
            transition: .3s all;
            margin-left: auto;
            margin-right: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            *{  
                color: $primary-medium; 
                font-size: 30px;
                line-height: 0;
            }
        }
        &.collapsed{
            .toggle-icon{
                transform: rotate(180deg);
            }
        }
    }
    .card-body{
        border: $no-border !important;
        border-radius: 0px;
        padding: 16px;
        &:first-child{
            padding-top: 0px;
        }
    }
}

.card{
    border-radius: 4px;
    border: 1px solid #DBDBDB;	
}
.data-card{
    position: relative;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    border: 1px solid #DBDBDB;	
    background: $white;
    .card-header{
        padding: 20px;
        background: $white;
        border: $no-border;
    }
    .card-body{
        padding: 20px;
        &:nth-child(1), &:nth-child(2){
            padding-top: 0px;
        }
    } 
    &.item-card{
        .card-header{
            padding: 20px;
            padding-bottom: 8px;
        }
    }
}

/* TAGS CARD */
.tags-card{
    position: relative;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    border: 1px solid #DBDBDB;	
    background: $white;
    padding: 20px;
    .title{
        margin-bottom: 8px;
        float: left;
        width: 100%;
    }
    .tags-holder{

    }
}

/* UPGRADE BAR */
$upgrade-bar-bg: $accent-dark;
$upgrade-bar-color: $white;
.upgrade-bar{
    display: flex;
    position: relative;
    width: 100%;
    min-height: 50px;
    background: $upgrade-bar-bg;
    justify-content: center;
    align-items: center;
    color: $upgrade-bar-color;
    padding: 10px 30px;
    & *{
        color: $upgrade-bar-color !important;
    }
    .cta{
        display: inline-block;
    }
}


/* HAS PLACEHOLDER - DIV */

.has-placeholder{
    &::after{
        content: attr(data-text);
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0px;
        top: 0px;
        left: 0px;
        border: 1px solid #DBDBDB;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 10px;
        text-align: center;
        font-size: 14px;
        z-index: 1;
    }
}

$table-notofication-family:$lato-font-family;
/* TABLE NOTIFICATION CARD */
.table-notification{
    position: relative;
    box-shadow: 10px 0px 0px 0px inset, 0 2px 4px 0 rgba(0,0,0,0.5); 
    td{
        display: table-cell;
        width: 100%; 
        .notif-container{ 
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: 16px;
            .icon{
                font-size: 20px !important;
                margin-right: 8px;
            }
            .message{
                font-size: 16px !important;
                font-family: $table-notofication-family;
            }
        }
    }
    .close-action{ 
        position: absolute;
        right: 32px;
    }
}

/* TABLE ADD ROW */
.add-data-row{
    &:hover{
        cursor: pointer;
    }
    td{
        display: table-cell;
        width: 100%; 
        text-align: center;
        font-weight: bold;
    }
}

/* TABLE LOADING */
tr.table-loading{
    td, th{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    i.spinner-grow{
        margin-right: 8px;
        width: 1rem;
        height: 1rem;
    }
}
/* INFINITE TBODY */
.infinite-tbody{
    overflow: hidden;
}

/* TABLE NO DATA */
.table-nodata{
    td, th{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
/* TIPPY */

.tippy-tooltip, .tippy-backdrop{
    background: transparent !important;
    background-color: transparent !important;
}

.tippy-tooltip{
    position: relative;
    padding: 0px;
}
.tippy-popper[x-placement^=right] .tippy-arrow{
    border-right: 20px solid #333;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    border-right-color: #FFFFFF !important; 
    filter: drop-shadow(-5px 0px 3px rgba(171,171,171,0.3));
    left: -19px;
}

/* POPOVER */


/* body-image */
.centerAlign{
    display: flex;
    justify-content: center;
}
.body-image{ 
    position: relative;
    & .tooltip {
        position: absolute;  
        transform: translate3d(-50%, -50%, 0);
        border-radius: 5px;
        pointer-events: none;
        z-index: 1000;
        opacity: 1;
    }
}
/* specialty-btn-img */
.cta{
    &:hover, &.active{
        .specialty-btn-img{
            background: transparent;
            filter: brightness(0) invert(1);
        }    
    }
}
.specialty-btn-img{
    background: #d8d8d8;
}

/* SIDE BAR */
$sidebar-bg: #303D72;
$sidebar-body-bg: $white;
$siderbar-body-shadow: 0 2px 10px 0 rgba(171,171,171,0.5);
$side-toggler-bg: $gray-light;
$side-toggler-color: #6A6A6A;
$side-li-active-shadow:-5px 0px 0px 0px #fff inset; 
.sidebar{
    position: fixed;
    top: 60px;
    left: 0px; 
    height: 100%; 
    box-shadow: $siderbar-body-shadow;
    transition: width 0.2s;
    .side-toggler{
        width: 25px;
        height: 25px;
        background: $side-toggler-bg;
        border-radius: 50%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        right: -10px;
        top: 28px;
        z-index: 5;
        transition: all 0.3s;
        &:hover{
            cursor: pointer;
        }
        *{
            color: $side-toggler-color;
            font-size: 20px;
        }
    }
    
    .side{
        width: 75px;
        height: 100%;
        background: $sidebar-bg; 
        z-index: 1;
        .side-list{
            padding: 0px;
            padding-top: 85px;
            display: flex;
            flex-direction: column;

            li{
                text-align: center; 
                transition: box-shadow 0.2s;
                &.active,&:hover{
                    box-shadow: $side-li-active-shadow;
                }
                *{
                    color: #fff;
                    font-size: 40px;
                }
            }
            
        }
    }

    .body{
        width: 0px; 
        .surgeon-profile-display{
            display: flex;
            flex-direction: column;
            padding-left: 28px;
            padding-right: 28px;
            .profile-img{
                text-align: center;
                margin-top: 24px;
                margin-bottom: 24px;
                border-radius: 50%;
                width: 100px;
                height: 100px;
                align-self: center;
                overflow: hidden;
                position: relative;
                img{
                    position: absolute;
                    width: 100px;
                    height: 100px;
                    left: 0;
                    top: 0;
                }
                a{
                    opacity: 0;
                    position: absolute;
                    width: 100%;
                    left: 0;
                    bottom: 0px;
                    height: 50px;
                    background: rgba(0, 1, 14, 0.8);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    font-weight: normal;
                    transition: 0.2s ease-in-out;
                }
                &:hover{
                    a{
                        opacity: 1;
                    }
                }
            }
            .name,
            .job,
            .number{
                margin-bottom: 12px;
            }
            .surgeon-details{
                .item{
                    margin-bottom: 8px;
                    &.skip{
                        margin-bottom: 16px;
                    }
                    h4{
                        margin-bottom: 4px;
                    }
                    .item-detail:first-child{
                        margin-right: 24px;
                    }
                }
            }
        }
    }
    &.collapsed{ 
        .body{
            width: 0px;
        }
    }
    &:not(.collapsed){ 
        width: 390px;  
        transition: width 0.2s;
        .side-toggler{ 
            transform: rotate(180deg);
        }
        .body{
            width: calc(100% - 75px);
            background: #FFFFFF;
            height: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 2;
            margin-left: 75px;
            /* box-shadow: $siderbar-body-shadow; */
        }
    }
}


/* POPOVER TEXT */
.popover:hover{
    cursor: pointer;
}



/* POPOVER */
.icon{
    transition: all .1s;
    fill: $primary-medium;
    .stroke-here{
        stroke: $primary-medium;
        transition: all .1s;
    }
    .fill-here{
        fill: $primary-medium;
        transition: all .1s;
    }
    &:hover{ 
        cursor: pointer;
        .stroke-here{
            stroke: $secondary-medium;
            transition: all .1s;
        }
        .fill-here{
            fill: $secondary-medium;
            transition: all .1s;
        }
    }
    &.negative{
        transition: all .1s;
        fill: $white;
        .stroke-here{
            stroke: $white;
            transition: all .1s;
        }
        .fill-here{
            fill: $white;
            transition: all .1s;
        }
        .negative-stroke-here{ 
            stroke: $primary-medium;
        }
        .negative-fill-here{
            fill: $primary-medium; 
        }
        &:hover{ 
            cursor: pointer;
            .stroke-here{
                stroke: $white;
                transition: all .1s;
            }
            .fill-here{
                fill: $white;
                transition: all .1s;
            }
        }
    }
}
.fadeAway-container{
    position: relative;
    .btns{
        position: absolute;
        z-index: 2;
        i{
            height: 32px;
            width: 32px;
            border-radius: 50%;
            border: 1px solid $secondary-medium;
            color: $secondary-medium;
            font-size: 32px;
            padding: 0px;
            display:flex;
            justify-content: center;
            align-items:center;
            background: $white;
        }
        &.next{
            top: -1px;
            right: 0px;
            width: 100px;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #f9f9f9 60%); 
        }
        &.prev{
            top: -1px;
            left: 0px;
            width: 100px;
            height: 100%; 
            display: flex;
            justify-content: flex-start;
            align-items: center;
            background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, #f9f9f9 60%);
        }
    }
}
.fadeAwayTabs{
    overflow-x: auto;
    overflow-y: hidden; 
    border: $no-border;
    border-radius: 0px;
    background: transparent;
    
    .nav{ 
        display: flex;
        flex-wrap: wrap;
        border: $no-border;
    }
}


/* TABS */

.corh-nav-tabs {
    &.nav-tabs{
        border-bottom: 1px solid $gray-light;
        height: 40px;
    }
    .nav-item:not(:first-child){
        padding: 0px 16px;
    }
    .nav-item:first-child{
        padding-right: 16px;
    }
    .nav-link{
        height: 40px;
        font-size: 16px;
        background: none !important;
        border: 0px;
        border-radius: 0px;
        padding:4px 0px;
        color: $primary-medium;
        margin-bottom: -1px;
        transition: 0.2s linear;
        display: flex;
        justify-content: center;
        align-items: center;
        &.active, &:hover{
            border-bottom: 0px $secondary-medium !important;
            box-shadow: 0px -5px 0px -1px $secondary-medium inset !important;
            text-decoration: none !important;
            color: $secondary-medium;
        } 
    } 
}

$text-preview-bg: $white;
$text-preview-title-color: #000222;
$text-preview-body-color: #4A4A4A;

/* TEXT EDITOR */
    /* preview */
    .text-preview{
        padding: 16px !important;
        border-radius: 4px;
        background: $text-preview-bg;
        .card-header{
            background: $text-preview-bg;
            border: 0px;
            padding: 0px 0px;
            padding-bottom: 15px;
            font-size: 20px;
            font-weight: bold;
            color: $text-preview-title-color;
        }
        .card-body{
            background: $text-preview-bg;
            padding: 0; 
            font-size: 16px;
            color: $text-preview-body-color;
        }
    }


$standard-preview-bg: $white;
$standard-preview-title-color: #000222;
$standard-preview-body-color: #4A4A4A;
/* STANDARD ELEMENT */  
    /* preview */
    .react-thumbnail-generator{
        height: 100% !important;
        img{
            width: 100% !important;
        }
    }
    .standard-preview{
        padding: 0px !important;
        border-radius: 4px;
        background: $standard-preview-bg;
        padding: 0px;
        .card-body{
            border-radius: 4px;
            padding: 0px;
            display: flex;
            overflow: hidden;
            .card-header{
                background: $standard-preview-bg;
                border: 0px;
                padding: 16px; 
                font-size: 20px;
                font-weight: bold;
                color: $standard-preview-title-color;
            }
            .card-content{ 
                background: $standard-preview-bg;
                border: 0px;
                padding: 0px 16px; 
                padding-bottom: 16px;
                font-size: 16px;
                color: $standard-preview-body-color;
            }
        }
    }


/* media album */
    /* preview */
    .media-element{
        padding:16px;
        .card-header{
            padding: 0px;
            padding-bottom: 12px; 
            font-size: 20px;
            font-weight: bold;
            border: 0px;
            background: $white;
        }
        .card-body{
            padding: 0px;
            padding-bottom: 16px;
            .preview{
                display: flex;
                .image{
                    padding-left: 0px;
                    padding-right: 8px;
                    width: 45%;
                }
                .description{
                    padding-left: 8px;
                    padding-right: 0px;
                    width: 55%;
                }
            }
            .thumbnails{
                .thumb-item{
                    width: calc(20% - 16px);
                    margin-top: 16px;
                    margin-right: 16px;
                    float: left;
                }
            }
        }
    }

/* ADD A NEW SECTION */

.add-section{
    padding:22px;
    border: 2px dashed #979797;
    border-radius: 0px;
    border-spacing: 5px;
    &.single{
        &:hover{
            cursor: pointer;
        }
        padding: 44px;
    }
    .card-header{
        border: 0;
        background: $white;
        text-align: center;
        font-weight: bold;
        font-size: 20px;
        padding:0;
    }
    .card-body{
        padding: 10% !important;
        padding-top: 28px !important;
        padding-bottom: 22px !important;
        ul{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            li{ 
                a{
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    color: $secondary-medium;
                    .icon{
                        margin-right: 8px;
                    }
                }
            }
        }
    }
}

/* CKEDITOR CUSTOM */
.cke_chrome{
    border: 0px !important;
    box-shadow: 0px 0px 0px 1px #DBDBDB;
    width: 100%;
}
.cke_bottom{
    display: none;
}
/* TEXT EDITOR */
.text-editor{
    padding: 0px;
    margin-bottom: 28px;

    .card-header{
        padding: 0px 12px;
        border: 0px;
        background: $primary-medium;
        display: flex;
        height: 45px;
        align-items: center; 
        .move-icon{
            margin-right: auto;
        }
        .copy-icon{
            margin-right: 24px;
        }
        .trash-icon{
            margin-right: -3px;
        }
    }
    .card-body{
        padding: 12px;
        &.editor-body{
            padding: 0px !important;
        }
    }
}

/* STANDARD EDITOR */

.standard-editor{
    padding: 0px;
    margin-bottom: 28px;

    .card-header{
        padding: 0px 12px;
        border: 0px;
        background: $primary-medium;
        display: flex;
        height: 45px;
        align-items: center; 
        .move-icon{
            margin-right: auto;
        }
        .copy-icon{
            margin-right: 24px;
        }
        .trash-icon{
            margin-right: -3px;
        }
    }
    .card-body{
        padding: 12px;
        &.standard-body{
            padding: 0px !important;
            position: relative;
            display: flex;
        }
        & .editor-body{
            padding: 0px !important;
            width: 50%;
            display: flex;
            .cke_chrome{
                display: flex;
            }
            .cke_inner {
                width: 100%;
                display: flex;
            }
            .cke_top{
                position: absolute;
                box-shadow: 0px 0px 0px 1px #DBDBDB;
                border:0px;
                width: calc(100% - 16px);
                z-index: 1;
                top: 0;
            }
            .cke_contents{
                margin-top: 42px;
                width: 100%;
                height: calc(100% - 42px) !important;
            }
        }
        & .img-body{
            padding: 0px !important;
            width: 50%;
            margin-top: 42px;
        }
    }
}

/* MEDIA EDITOR */

.media-editor{
    padding: 0px;
    margin-bottom: 28px;

    & > .card-header{
        padding: 0px 12px;
        border: 0px;
        background: $primary-medium;
        display: flex;
        height: 45px;
        align-items: center; 
        .move-icon{
            margin-right: auto;
        }
        .copy-icon{
            margin-right: 24px;
        }
    }
    .card-body{
        padding: 12px;
        padding-top: 20px;
        &.media-body{
            background: #efefef;
        }
        .card.media-item{
            margin-bottom: 20px;
            .card-header{
                background: transparent;
                border: 0px;
                display: flex;
                .move-icon{
                    margin-right: 4px;
                } 
                .copy-icon{
                    margin-left: 24px;
                    margin-right: 24px;
                }
                .trash-icon{
                    margin-right: -3px;
                }
            }
            & > .card-body{
                display: flex;
                position: relative;
                width: 100%;
                &.standard-body{
                    padding: 0px !important;
                    position: relative;
                    display: flex;
                    & .editor-body{
                        padding: 0px !important;
                        width: 50%;
                        display: flex;
                        .cke_chrome{
                            display: flex;
                        }
                        .cke_inner {
                            width: 100%;
                            display: flex;
                        }
                        .cke_top{
                            position: absolute;
                            box-shadow: 0px 0px 0px 1px #DBDBDB;
                            border:0px;
                            width: calc(100% - 16px);
                            z-index: 1;
                            top: 0;
                            left: 0px;
                        }
                        .cke_contents{
                            margin-top: 42px;
                            width: 100%;
                        }
                    }
                    & .img-body{
                        padding: 0px !important;
                        width: 50%;
                        margin-top: 42px;
                    } 
                }
            }
        }
    }
}

/* LABEL INFO */
.info-label{    
    border: 2px dashed #9f9f9f;
    overflow: hidden;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f9f9f9;
    padding-top: 66.66%;
    box-sizing: content-box;
	position: relative; 
    width: calc(100% - 3px);
    margin: 0 !important;
    &:hover{
        cursor: pointer;
    }
    &.changeable{
        &:hover{
            .change-label{
                opacity: 1;
            }    
        }
        .change-label{
            color: $white;
            opacity: 0;
            transition: opacity 0.2s;    
            z-index: 1;
            width: 100%;    
            background: rgba($color: #000000, $alpha: 0.5);
        }
    }
    & > *:not(input){
        position: absolute;
        top: 0;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    input{
        opacity: 0;
        margin-top: -30px;
    }
}

/* POPOVER ICON */
.popovericon{
    line-height: 1em;
}


/* NAVIGATOR TABS */
$nav-color: $primary-medium;
$nav-active-color: $secondary-medium;
$nav-border-bottom-color: #DBDBDB;
.navigator-tabs{
    .nav-tabs{
        border-bottom: 0px solid $nav-border-bottom-color;
        position: relative;
        &::after{
            content: '';
            width: calc(100% - 40px);
            position: absolute;
            height: 1px;
            bottom: -1px;
            background: $nav-border-bottom-color ;
            z-index: -1;
        }
    }
    .nav-item{
        border: 0 !important;
        background: transparent;
        padding: 0px;
        padding-bottom: 8px;
        margin-right: 28px;
        height: 32px;
        line-height: 24px;
        font-weight: bold;
        transition: box-shadow 0.1s;
        color: $nav-color;
        &.active, &:hover{
            color: $nav-active-color;
            text-decoration: none !important;
            box-shadow: 0px -3px 0px 0px $nav-active-color inset;            
        }
    }
    .tab-content{
        padding-top: 16px;
        width: 100%;
        .tab-pane{
            width: 100%;
        }
    }
}

$end-of-loading-bg: #f4f4f4;
$end-of-loading-color: $text-color;
$end-of-loading-anchor-color: $primary-medium;
/* END OF LOADING */
.end-of-loading, .loading-infi{
    background: $end-of-loading-bg;
}
.end-of-loading-title{
    font-size: 28px !important;
    color: $end-of-loading-color;
    margin-top: 32px;
}
.end-of-loading-description{
    color: $end-of-loading-color;
    font-size: 16px;
    margin-bottom: 32px;
    a{
        color: $end-of-loading-anchor-color;
        &:hover{
            color: $end-of-loading-anchor-color;
        }
    }
}