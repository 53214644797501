/* CONTAINERS

    -container
    -container-fluid 
     

*/ 
@media (min-width: 1400px){
    .container {
        max-width: 1280px;
    }   
}
 
main{
    padding-top: 24px !important;
    padding-bottom: 40px !important;
}