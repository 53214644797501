/* 
    TYPHOGRAPHY

    -headings
    -paragraphs
    -anchors
    -span
    -icons
    
*/

@import url('https://fonts.googleapis.com/css?family=Lato:300,400,500,700|Oxygen:300,400,700&display=swap');

*:not(.fa):not(.mdi){
    font-family: 'Oxygen', sans-serif; 
}
/* 
h1,h2,h3,h4,h5,h6,b,strong, .bold, a, .link, 
.search-results, .cta, .teriary-cta{
    font-family: $lato-font-family; 
}

h1{font-size: $h1-size; line-height: 1em;}
h2{font-size: $h2-size; line-height: 1em;}
h3{font-size: $h3-size; line-height: 1em;}
h4{font-size: $h4-size; line-height: 1em;}
h5{font-size: $h5-size; line-height: 1em;}
h6{font-size: $h6-size; line-height: 1em;}
p{font-size: $body-size;}

h1,h2,h3,h4,h5,h6,p{ 
    margin-bottom: 0px;

    &.bold{
        font-weight: 700;
    }
    &.regular{
        font-weight: 400;
    }
    &.light{
        font-weight: 300;
    }
} */
main{
    min-height: calc(100vh - 120px);
}
.section{
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
}

.page-title,
.page-description,
.page-detail{
    margin-bottom: 24px;
}

.page-title{ 
    font-weight: bold !important;
    color: #000222 !important;
}
.page-sub-title{ 
    font-weight: bold !important;
    color: #4a4a4a !important;
    margin-bottom: 8px;
}

.page-description{
    font-size: 20px;
    font-weight: normal;
}

.page-detail{
    font-size: 16px;
    font-weight: normal;
}

.end-of-loading-title{
    margin-bottom: 16px;
}
.end-of-loading-description{
    margin-bottom: 16px;
}

.title-row{
    margin-bottom: 16px;
}

.page-toolbar{ 

    .sub-div{
        margin-bottom: 28px;
    }
    .cta{ 
        margin-bottom: 28px !important;
    }
    @media screen and (max-width: 768px){
        .has-expand{
            flex-direction: column !important
        }
        .cta{
            margin: 0px !important;  
            margin-bottom: 28px !important;
        }
    }
}