.bg-secondary, 
.btn-secondary,
.navbar-secondary {
    background: #9b9b9b !important;
}

.bg-dark{
    background: #565656 !important;
}
.text-dark{
    color: #565656 !important;
}
 
body{
    font-size: 14px;
}
.blacken-image{
    filter: contrast(0) brightness(0);
}
.whiten-image{
    filter: contrast(0) brightness(100);
}
.header-logo{
    height: 40px;
    filter: contrast(0) brightness(0);
}
#masterhead .dropdown-toggle::after,
.no-caret ::after {
    display:none;
}
#masterhead{
    height: 60px;
}
#masterhead .nav-item, 
#masterhead .nav-item .nav-link{
    display: flex;
    justify-content: center;
    align-items: center;
}
#masterhead .nav-link{
    /*color: #565656;*/
    padding: 0px 15px; 
}
#masterhead .nav-link:hover,
#masterhead .nav-link:hover *{
    color: #fff;
}
.mega-menu{ 
    margin-top: 0px;
    border-radius: 0px;
    width: 100%;
    padding: 15px 0px;
    /*background: #d8d8d8;*/
    border: 0px;
}
nav a{
    transition: 0.1s ease-in-out;
}

#masterhead .navbar-nav{
    height: 60px;   
}
#masterhead .nav-item:hover, 
#masterhead .nav-item.show,  
#masterhead .nav-item.show .nav-link{
    /*background: #565656;*/
    color: #fff !important;
}
#masterhead .nav-item > a{
    font-weight: 600;
}
#masterhead .mega-menu .list-inline-item{
    padding: 15px 20px;
    font-size: 14px;
}

#masterhead .mega-menu .list-inline-item a{
    color: #4c4c4c;
}
.mega-menu a:hover{
    font-weight: bold;
}

.border-6a6a6a{
    border-color: #6a6a6a !important;
}

.nurse-icon{
    height: 30px;
    width: 30px;
}

.w15{width: 15px;}
.w20{width: 20px;} 

.search-dropdown{ 
    width: 100%;
    margin-top: 0px;
    border-radius: 0px;
    padding: 20px 0px;
    /*background: #e0e0e0;*/
}

.masterhead-navs{
    margin-left: -1px;
}
.masterhead-navs .nav-item{
    padding: 0px 15px;
}

.has-before-border::before{
    content: '';
    /*position: absolute;*/
    height: 80%;
    width: 1px;
    background: #6a6a6a;
    align-self: center; 
}
.masterhead-navs:hover,
.masterhead-navs:hover .nav-link{
    color: #fff !important;
}

.fit-dropdown{
    margin-top: 0px;
    width: 100%;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border: 0px;
    /*background: #d8d8d8;*/
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 0px;
}
.click-dropdown.show,
.click-dropdown.show > a,
.click-dropdown.show i,
.click-dropdown.show > li,
.click-dropdown.show > li > a{
    background: var(--level1);
    color: #fff !important;
}
.fit-dropdown, 
.fit-dropdown .dropdown-menu{
    min-width: auto !important;
    font-size: 14px;
}
.fit-dropdown li{
    color: #565656;
    padding-left: 0px;
    padding-right: 0px;
    /*background: transparent;*/
}

.fit-dropdown li > a{
    color: #565656;
}

.fit-dropdown li > a:hover{
    font-weight: bold;
}
