.line-form-group{ 
/*     padding-top: 10px;
    margin-bottom: 24px; */
}
.line-form-group > label{
    color: #4A4A4A;	
    font-family: Lato;	
    font-size: 14px;
    font-weight: bold;	 
    margin-bottom: 0px;
}
.line-form-group input{
    border-top: 0 !important;
    border-right: 0 !important;
    border-left: 0 !important;
    border-radius: 0px !important;
    box-shadow: 0px 0px 0px !important;
    background: transparent !important;
    padding-left: 0px;
    padding-right: 0px;
    min-height: 40px;
    border-bottom: 2px solid #dadada;
}
input.search-input::after{
    display: inline-block;
    font: normal normal normal 24px/1 "Material Design Icons";
    font-size: inherit;
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
        content: "\F349";
}
.line-form-group input:focus{
    border-bottom: 2px solid #000000;
}
.line-form-group.with-prepend input{
    padding-right: 20px;
}


.line-form-group label{
    position: absolute;
    bottom: 9px;
    transition: .1s ease-in-out;
} 

.line-form-group.active label{
    bottom: 35px;
    font-size: 12px !important;
    transition: .2s ease-in-out;
}
.line-form-group .input-group-prepend{
    &{
        border-top: 0;
        border-right: 0;
        border-left: 0;
        border-bottom: 0;
        border-radius: 0px;
    }
    .input-group-text{
        font-size: 20px;
        padding: 0;
        margin-left: -16px;
        border:0;
        background: transparent;
    }
}

 
/* CUSTOM TABS */
.custom-tabs {
    .nav-item:not(:first-child){
        margin: 0px 12px;
    }
    .nav-item:first-child{
        margin-right: 12px;
    }
    .nav-link{
        font-size: 16px;
        background: none !important;
        border: 0px;
        border-radius: 0px;
        padding:4px 0px;
    }
}