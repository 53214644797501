// Small devices (landscape phones, 576px and bellow)

@media (max-width: 576px) { 
    @for $i from 0 through 60 {	
        /* PADDING */
        .p-xs#{$i}{padding: 1px * $i !important}
        .p-xsx#{$i}{padding-left: 1px * $i !important;padding-right: 1px * $i !important;}
        .p-xsy#{$i}{padding-top: 1px * $i !important;padding-bottom: 1px * $i !important;}
        .p-xst#{$i}{padding-top: 1px * $i !important;}
        .p-xsb#{$i}{padding-bottom: 1px * $i !important;}
        .p-xsr#{$i}{padding-right: 1px * $i !important;}
        .p-xsl#{$i}{padding-left: 1px * $i !important;}


        /* MARGINS */	
        .m-xs#{$i}{margin: 1px * $i !important;}
        .m-xsx#{$i}{margin-left: 1px * $i !important;margin-right: 1px * $i !important;}
        .m-xsy#{$i}{margin-top: 1px * $i;margin-bottom: 1px * $i !important;}
        .m-xst#{$i}{margin-top: 1px * $i !important;}
        .m-xsb#{$i}{margin-bottom: 1px * $i !important;}
        .m-xsr#{$i}{margin-right: 1px * $i !important;}
        .m-xsl#{$i}{margin-left: 1px * $i !important;}
        
        .fs-xs#{$i}{font-size: 1px * $i !important;}
    } 
    .m-xsx-auto{margin-left: auto !important;margin-right: auto !important;}
    
    .container{
        padding-right: 20px;
        padding-left: 20px;
    }

    .popover{
        max-width: 80% !important;
        min-width: 80% !important;
    }

    .copy_from_menu{
        display: none !important;
    }
    .border-xs-0{
        border: none !important;
    }
    .text-xs-underline{
        text-decoration: underline !important;
    }
    .w-xs-100{
        width: 100% !important;
    }
        
    *:not(.fa):not(.mdi) {
        word-break: break-word;
    }
}
@media (min-width: 220px) and (max-width: 376px) { 
    @for $i from 0 through 60 {	
        /* PADDING */
        .p-xxs#{$i}{padding: 1px * $i !important}
        .p-xxsx#{$i}{padding-left: 1px * $i !important;padding-right: 1px * $i !important;}
        .p-xxsy#{$i}{padding-top: 1px * $i !important;padding-bottom: 1px * $i !important;}
        .p-xxst#{$i}{padding-top: 1px * $i !important;}
        .p-xxsb#{$i}{padding-bottom: 1px * $i !important;}
        .p-xxsr#{$i}{padding-right: 1px * $i !important;}
        .p-xxsl#{$i}{padding-left: 1px * $i !important;}


        /* MARGINS */	
        .m-xxs#{$i}{margin: 1px * $i !important;}
        .m-xxsx#{$i}{margin-left: 1px * $i !important;margin-right: 1px * $i !important;}
        .m-xxsy#{$i}{margin-top: 1px * $i;margin-bottom: 1px * $i !important;}
        .m-xxst#{$i}{margin-top: 1px * $i !important;}
        .m-xxsb#{$i}{margin-bottom: 1px * $i !important;}
        .m-xxsr#{$i}{margin-right: 1px * $i !important;}
        .m-xxsl#{$i}{margin-left: 1px * $i !important;} 

        .fs-xxs#{$i}{font-size: 1px * $i !important;}
    }  
    
    *:not(.fa):not(.mdi) {
        word-break: break-word;
    }
    .border-sm-0{
        border: none !important;
    }
}


// Medium devices (tablets, 768px and up)
@media (max-width: 769px) and  (min-width: 576px) {
    
    @for $i from 0 through 60 {	
        /* PADDING */
        .p-md#{$i}{padding: 1px * $i !important}
        .p-mdx#{$i}{padding-left: 1px * $i !important;padding-right: 1px * $i !important;}
        .p-mdy#{$i}{padding-top: 1px * $i !important;padding-bottom: 1px * $i !important;}
        .p-mdt#{$i}{padding-top: 1px * $i !important;}
        .p-mdb#{$i}{padding-bottom: 1px * $i !important;}
        .p-mdr#{$i}{padding-right: 1px * $i !important;}
        .p-mdl#{$i}{padding-left: 1px * $i !important;}


        /* MARGINS */	
        .m-md#{$i}{margin: 1px * $i !important;}
        .m-mdx#{$i}{margin-left: 1px * $i !important;margin-right: 1px * $i !important;}
        .m-mdy#{$i}{margin-top: 1px * $i;margin-bottom: 1px * $i !important;}
        .m-mdt#{$i}{margin-top: 1px * $i !important;}
        .m-mdb#{$i}{margin-bottom: 1px * $i !important;}
        .m-mdr#{$i}{margin-right: 1px * $i !important;}
        .m-mdl#{$i}{margin-left: 1px * $i !important;}

        .fs-md#{$i}{font-size: 1px * $i !important;}
    } 
    .m-mdx-auto{margin-left: auto !important;margin-right: auto !important;}


    .border-md-0{
        border: none !important;
    }
    .text-md-underline{
        text-decoration: underline !important;
    }
    .w-md-100{
        width: 100%;
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 770px) and (max-width: 992px) {
    
    @for $i from 0 through 60 {	
        /* PADDING */
        .p-lg#{$i}{padding: 1px * $i !important}
        .p-lgx#{$i}{padding-left: 1px * $i !important;padding-right: 1px * $i !important;}
        .p-lgy#{$i}{padding-top: 1px * $i !important;padding-bottom: 1px * $i !important;}
        .p-lgt#{$i}{padding-top: 1px * $i !important;}
        .p-lgb#{$i}{padding-bottom: 1px * $i !important;}
        .p-lgr#{$i}{padding-right: 1px * $i !important;}
        .p-lgl#{$i}{padding-left: 1px * $i !important;}


        /* MARGINS */	
        .m-lg#{$i}{margin: 1px * $i !important;}
        .m-lgx#{$i}{margin-left: 1px * $i !important;margin-right: 1px * $i !important;}
        .m-lgy#{$i}{margin-top: 1px * $i;margin-bottom: 1px * $i !important;}
        .m-lgt#{$i}{margin-top: 1px * $i !important;}
        .m-lgb#{$i}{margin-bottom: 1px * $i !important;}
        .m-lgr#{$i}{margin-right: 1px * $i !important;}
        .m-lgl#{$i}{margin-left: 1px * $i !important;}

        .fs-lg#{$i}{font-size: 1px * $i !important;}
    } 
    .m-lgx-auto{margin-left: auto !important;margin-right: auto !important;}
    

    .border-lg-0{
        border: none !important;
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    
    @for $i from 0 through 60 {	
        /* PADDING */
        .p-xl#{$i}{padding: 1px * $i !important}
        .p-xlx#{$i}{padding-left: 1px * $i !important;padding-right: 1px * $i !important;}
        .p-xly#{$i}{padding-top: 1px * $i !important;padding-bottom: 1px * $i !important;}
        .p-xlt#{$i}{padding-top: 1px * $i !important;}
        .p-xlb#{$i}{padding-bottom: 1px * $i !important;}
        .p-xlr#{$i}{padding-right: 1px * $i !important;}
        .p-xll#{$i}{padding-left: 1px * $i !important;}


        /* MARGINS */	
        .m-xl#{$i}{margin: 1px * $i !important;}
        .m-xlx#{$i}{margin-left: 1px * $i !important;margin-right: 1px * $i !important;}
        .m-xly#{$i}{margin-top: 1px * $i;margin-bottom: 1px * $i !important;}
        .m-xlt#{$i}{margin-top: 1px * $i !important;}
        .m-xlb#{$i}{margin-bottom: 1px * $i !important;}
        .m-xlr#{$i}{margin-right: 1px * $i !important;}
        .m-xll#{$i}{margin-left: 1px * $i !important;}

        .fs-xl#{$i}{font-size: 1px * $i !important;}
    } 
    .m-xlx-auto{margin-left: auto !important;margin-right: auto !important;}
}


// RESPONSIVE MODAL

@media (min-width: 276px) and (max-width: 576px){ 
    .modal-body,
    .modal-footer,
    .modal-header{
        padding-left: 10px !important;
        padding-right: 10px !important;
        padding-top: 30px !important;
        padding-bottom: 0px !important;
    }
    .modal-body .container,
    .modal-footer .container,
    .modal-header .container{
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}
@media (min-width: 576px) and (max-width: 767.98px){ 
    .modal-body,
    .modal-footer,
    .modal-header{
        padding-left: 10px !important;
        padding-right: 10px !important;
        padding-top: 30px !important;
        padding-bottom: 0px !important;
    }
    .modal-body .container,
    .modal-footer .container,
    .modal-header .container{
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}

@media (max-width: 576px) and (min-width: 576px) {
    .modal-dialog {
        max-width: 90% !important;
        margin: 1.75rem auto;
    }
}
@media (min-width: 576px) {
    .modal-lg, .modal-xl {
        max-width: 90% !important;
        margin: 1.75rem auto;
    }
}

@media (min-width: 992px){
    .modal-lg{
        max-width: 800px;
    }
    .modal-xl {
        max-width: 1000px;
    }
}
@media (min-width: 1200px){
    .modal-xs{
        max-width: 400px !important;

    }
    .modal-md{
        max-width: 600px !important;
    }
    .modal-lg{
        max-width: 800px !important;
    }
    .modal-xl {
        max-width: 1050px !important;
    }
}