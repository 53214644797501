/*TABLE*/

table{
  margin-bottom: 0px !important;
} 
td, th, tr{
	min-height: 44px !important;
	vertical-align: middle !important;
	/* padding:10px 15px !important; */
}
.table thead th{
	border: 0px;
}
thead{
	background: var(--level1);
}

thead td,
thead th{
	color: white;
}

.table-striped tbody tr:nth-of-type(even){
	background: var(--level4);
}
.table-striped tbody tr:nth-of-type(odd){
	background: var(--level9);
}

/* .table-striped tbody tr:hover,
.table-striped tbody tr:hover{
	background: var(--level3);
	color: var(--level5)
}
 */
tr._selected,
tr._selected td,
tr._selected th{
		/* background: var(--level3) !important; */
		font-weight: bold;
		color: #000;
}

.table-fixed{
  width: 100%; 
  tbody{  
    overflow-y:auto;
    width: 100%;
    }
  thead,tbody,tr,td,th{
    display: flow-root;
  }
  tbody{
    tr{
      
      align-items: stretch;
      display: flex;
      vertical-align: middle !important;
      width: 100%;
    }
    td{
      padding-left: 20px;
      padding-right: 20px;
      height: 100%;
      align-self: center!important;
      border: 0px !important;
    }
  }
  thead {
    tr{
      th{
        float:left; 
      }
    }
  }
}

td label{
  margin: 0px;
}
td .badge{
  padding: 5px 15px;
  font-size: 14px;
}

tr._active_fixed *{
  font-weight: bold !important;
}

.table-hover-bold tbody > tr a{
 /*  font-weight: normal !important; */
  transition: .1s ease-in-out;
} 
.table-hover-bold tbody > tr:hover *{
  color: black !important;
}
.table-hover-bold tbody > tr a:hover{
  font-weight: bold !important;
}

.table-hover tbody tr:hover, .table-hover tbody tr:hover *, .table-hover tbody th:hover {
   /*  background: var(--level3); */
    color: black;
}
.table-fixed tr{ 
    align-items: center;
}

thead i:hover{
  cursor: pointer;
}

.active-row{
  background: var(--level5)
}