/* 

    COLORS
    -primary-light
    -primary-medium
    -primary-dark
    
    -secondary-light
    -secondary-medium
    -secondary-dark
    
    -accent-light
    -accent-medium
    -accent-dark
    
    -card-background

    -grey-background
    
    -text-color

            -green
            -light-green
            -dark-green
            -purple
            -light-purple
            -dark-purple
            -blue
            -light-blue
            -dark-blue
            -yellow
            -light-yellow
            -dark-yellow
            -red
            -light-red
            -dark-red


*/
$white: #FFFFFF;
$light-bg: #FFFFFF;
$black: #000000;
$black-bg: #000000;

$primary-light: #F0EFFF;
$primary-medium: #3451A9;
$primary-dark: #3451A9;

$secondary-light: #9013FE; 
$secondary-medium: #5C5AC8; //hover over colors, selected
$secondary-dark: #5C5AC8;
$accent-light: rgb(7, 10, 9);
$accent-medium: #08CE92;
$accent-dark: #208362;

$gray-light:#D8D8D8;
$gray-medium:#979797;
$gray-dark:#979797;

$card-background: #FFFFFF;
$grey-background: #F9F9F9;
$text-color-light: #FFFFFF;
$text-color: #4A4A4A;
$text-color-dark: #000222;

$violet-light: #7674D6;

$success: #0E7505;
$warning: #F5A623;
$information: #4A90E2;
$error: #D0021B;


$primary-light: #F2F5FF;
$primary-medium: #3451A8;
$primary-dark: #102040;

$secondary-light: #F0EFFF;
$secondary-medium: #5C5AC8;
$secondary-dark: #22216A;

$accent-light: #BBFFEA;
$accent-medium: #08CE92;
$accent-dark: #208362;

$card-bg: #FFFFFF;
$grey-bg: #F9F9F9;

$body-bg: #f9f9f9;
$editor-bg: #303D72;

$holy-gradient:	linear-gradient(359.91deg, #08CE92 0%, #5C5AC8 35.79%, #3451A9 100%);;

*{
    // color: $text-color;
}


a, .link{
    // color: $primary-medium;
}
a:hover, .link:hover{
    cursor: pointer;
    text-decoration: underline !important;
}

h1,h2,h3,h4,h5,h6{
    color: $text-color-dark;
}

.success,.valid{
    color: $success !important;
}
.error,.invalid{
    color: $error !important;
}
.information{
    color: $information !important;
}
.warning{
    color: $warning !important;
}