/* MODALS
    -backdrop

    -xs
    -sm
    -md
    -lg
    -xl
*/

.modal-backdrop{ 
    background-color: #00010E
}
.modal-backdrop.show{
    opacity: 0.85;
}
.modal-xs{max-width: 100% !important;}
// .modal-sm{max-width: 100% !important;}
.modal-md{max-width: 100% !important;}
.modal-lg{max-width: 100% !important;}
.modal-xl{max-width: 100% !important;}
.modal-full{max-width: 100% !important;}

.modal-xs .modal-content,
.modal-sm .modal-content,
.modal-md .modal-content,
.modal-lg .modal-content,
.modal-xl .modal-content,
.modal-full .modal-content{
    padding:0px !important; 
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 10px 0 rgba(171,171,171,0.5);
}
.modal-xs .modal-header,
.modal-sm .modal-header,
.modal-md .modal-header,
.modal-lg .modal-header,
.modal-xl .modal-header,
.modal-full .modal-header{
    background-color: #FFFFFF;
    padding-top:20px !important;
    padding-left:20px !important;
    padding-right:20px !important;
    padding-bottom:0px !important;
    border:$no-border;
    .modal-title{
        margin: 0px;
        padding: 0px !important;
        font-size: 24px;
        font-weight: bold;
        font-family: $lato-font-family;
        line-height: 32px;
        letter-spacing: -0.15px;
        &.text-center{
            margin: 0 auto;
        }
    }
    &.text-center{
        .modal-title{
            margin: 0 auto;
        }
    }
}
.modal-xs .modal-body,
.modal-sm .modal-body,
.modal-md .modal-body,
.modal-lg .modal-body,
.modal-xl .modal-body,
.modal-full .modal-body{
    background-color: #FFFFFF;
    padding-top:20px !important;
    padding-bottom:20px !important;
    padding-left:20px !important;
    padding-right:20px !important;
    border:$no-border;
    line-height: 24px;
    font-size: 16px;
    &.text-center{
        margin: 0 auto;
    }
}
.modal-xs .modal-footer,
.modal-sm .modal-footer,
.modal-md .modal-footer,
.modal-lg .modal-footer,
.modal-xl .modal-footer,
.modal-full .modal-footer{
    background-color: #FFFFFF;
    padding-top:0px !important;
    padding-bottom:20px !important;
    padding-left:20px !important;
    padding-right:20px !important;
    border:$no-border;
    &.text-center{
        justify-content: center;
    }
    &.text-left{
        justify-content: start;
    }
}
.modal-sub-title{
    margin-bottom: 24px;
}
.modal-xs .modal-dialog{
    max-width: 372px !important;
    margin: 0 auto;
}
.modal-sm .modal-dialog{
    max-width: 522px;
    margin: 0 auto;
}
.modal-md .modal-dialog{
    max-width: 598px;
    margin: 0 auto;
}
.modal-lg .modal-dialog{
    max-width: 810px;
    margin: 0 auto;
}
.modal-xl .modal-dialog{
    max-width: 1040px;
    margin: 0 auto;
}
.modal-full{
    .modal-dialog{
        max-width: 100%;
        height: 100vh;
        margin: 0 auto;
    }
    .modal-header{
        text-align: center !important;
        position: relative;
        background: #F9F9F9;
        .modal-title{
            position: absolute;
            right: 0;
            left: 0;
            padding-left: 32px;
            padding-right: 32px;
        }
    }
    .modal-content{
        height: 100%;
        background: #F9F9F9;
        .modal-body{
            background: #F9F9F9;
        }
    }
}
.modal-body{
    width: 100%;
}


/* ADD SIZES */
.add-size-card{ 
    position: relative; 
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word; 
    background: $white;
    overflow: auto;
    max-height: 275px;
    padding: 20px;
    padding-left: 23px;
    padding-top: 0px;
    .card-body{
        position: relative; 
        padding: 20px;
        box-shadow: $card-shadow-sm;
        border-radius: 4px;
        .line-form-group-v2{
            margin-top: 10px;
        }
    }
    & > .row{
        margin-bottom: 20px;
        &:last-child{
            margin-bottom: 0px;
        }
        .add-card{
            border: 2px dashed #B7B7B7;
            min-height: 98px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
        }
    }
    .cta-tertiary{
        margin: 0px;
        font-size: 28px;
        & *{
           color: $primary-medium;
        }
    }
}

.modal-body.add-glove-modal-body{
    padding-top: 0px !important;
    .line-form-group-v2{
        margin-top: 20px;
        margin-bottom: 16px;
    }
}
.form-modal-body{
    padding-top: 0px !important;
    .line-form-group-v2{
        margin-top: 20px;
        margin-bottom: 16px;
    }
}
.modal-table{
    height: 355px;
    position: relative;
    &.infi-table{
        &::after{ 
            height: 65px !important; 
            background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #F9F9F9 90%) !important;
        }
    }

    table{
        z-index: 2;
        min-height: 350px;
        max-height: 350px; 
        position: absolute;
        td,th{
            padding: 8px 12px !important;
            .specialty-span{
                margin:0px !important;
                height: 27px;
            }
            &:last-child{
                padding-right: 12px;
            }
        }
        tbody{
            max-height: 309px;
            overflow: auto; 
            display: flex;
            flex-direction: column;
        } 
        tr{
            display: inline-table;
            width: 100%;
        }
        thead {
            tr{
                width:  calc(100% - 10px);
            }
        }
    }
    &.with-checkbox, &.with-radio{
        td:first-child, th:first-child{
            padding-right: 0px !important;
            text-align: center !important;
        }
        td:only-child, th:only-child{
            padding-right: 12px;
        }
    }
}

.select-procedures-body{
    & > p{
        margin-bottom: 16px;
    }
}

.assign-items-body{
    .line-form-group-v2{
        margin-top: 16px;
        margin-bottom: 16px;
    }
}

/* PREVIEW ITEM */
.preview-item-modal{
    padding: 0px !important;
    .modal-header{
        height: 58px; 
        display: flex;
        align-items: center;

        .close{
            padding:0;
            margin-right: 0;
        }
        .modal-title{
            line-height: 16px;
        }
    }
}
.preview-item-body{

}

/* PAYMENT MODAL */
.upgrade-plan-modal{
        padding: 0px !important;
    .modal-dialog{
        background-image: linear-gradient(225deg, rgba(8, 206, 146, 0.8) 0%, rgba(92, 90, 200, 0.8) 54.64%, rgba(52, 81, 169, 0.8) 100%), url('../images/payment-Image.jpg') !important; 
        background-size: 50% 135%;
        background-repeat: no-repeat;
    }
    .close{ 
        z-index: 3;
        position: absolute;
        top: 30px;
        right: 30px;
    }
    .modal-header,
    .modal-content{
        background: transparent !important;
    }
    .modal-content::before{
        content:'';
        height: 100vh;
        position: fixed;
        width: 62%;
        background: white;
        right: -5%;
        transform: skewX(-10deg);
        z-index: 1;
    }
    .modal-body{
        margin-top: -20px;
        background: transparent !important;
        z-index: 2;
    }
    .payment-form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .checkout{
            width: 75%; 
            @media (max-width: 769px)  {
                &{
                    width: 100%;
                }
            }
        }
        .title{
            margin-bottom: 40px;
        }
        .desc{
            margin-bottom: 40px;
            font-size: 16px;
        }
    }
    .total-billed{
        font-size: 20px;
        font-weight: lighter;
        margin-right: 42px;
    }
    .bill{
        font-weight: bold;
        font-size: 20px;
    }
}

/* THANK YOU MODAL */
.thank-you-modal{
    padding: 0px !important;

    .modal-dialog{
        background-image: linear-gradient(135deg, rgba(8, 206, 146, 0.8) 0%, rgba(92, 90, 200, 0.8) 75%, rgba(52, 81, 169, 0.8) 100%) !important;
        background-size: 50%;
        background-repeat: no-repeat;
        background-position: right;  
    }
    .close{ 
        z-index: 3;
        position: absolute;
        top: 30px;
        right: 30px;
    }
    .modal-header,
    .modal-content{
        background: transparent !important;
    }
    .modal-content::before{
        content:'';
        height: 100vh;
        position: fixed;
        width: 65%;
        background: white;
        left: -5%;
        transform: skewX(-10deg);
        z-index: 1;
    }
    .modal-body{
        margin-top: -20px;
        background: transparent !important;
        z-index: 2;
    }
    .thank-you{
        
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        .title{
            margin-bottom: 40px;
        }
        .desc{
            margin-bottom: 40px;
            font-size: 16px;
        }
    }
}

/* PREVIEW ITEM MODAL */
.preview-item-modal {
    .modal-content{
        background: #F9F9F9 !important;
    }   
    .swiper-container{
        width: 100%;
    }
    .modal-header{
        .modal-title{
            position: relative;
            margin-right: -24px !important;
            width: calc(100% + 8px);
            line-height: 25px;
        }
    }
}

