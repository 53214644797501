.surgeon-card{
    min-height: 100%; 
    padding-bottom: 30px;
}
.surgeon-card .card{
    min-height: 100%; 
    transition: .1s ease-in-out;
}
.surgeon-card:hover .card{
    cursor: pointer;
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,.175)!important;
}
.surgeon-card:hover{
	text-decoration: none !important;
}
.surgeon-card img{
	width: 100px;
	height: 100px;
}

.surgeon-card .card-body{
	background: var(--level5);
}

.surgeon-card .specialty-pill,
.counter-pill{
	height: 25px;
	font-size: 12px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
}

.surgeon-card .badge,
.counter-pill{
	padding: 7px 15px !important;
  font-size: 14px !important;
  font-weight: 600;
}

.surgeon-name, 
.surgeon-sub{
	font-size: 16px;
	margin:0px;
} 

.heading{
	font-size: 14px;
	font-weight: bold;
}

.profile-img-overlay .card-img-overlay,
.profile-img-overlay .card-img-overlay a{
    display: none;
}

.profile-img-overlay:hover,
.profile-img-overlay:hover .card-img-overlay{
    display: block;
    cursor: pointer;
}

.profile-img-overlay:hover .card-img-overlay a{
    background: rgba(0, 0, 0, 0.6);
    border-radius: 0px !important;
    display: block;
}

.disabled-specialty-card,
.disabled-specialty-card:hover{
    opacity: 0.4;
    cursor: not-allowed;
    pointer-events: none;
}