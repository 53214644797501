/* buttons */
.btn{
	font-weight: bold;
} 

.btn{
    cursor: pointer;
}
.btn-primary,
.btn-outline-primary{
    font-weight: bold;
    border-color: var(--level1) !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.btn-primary{
    color: #ffffff;
    background: var(--level1);
}
.btn-primary:hover{
    border-color: var(--level1) !important;
    background: var(--level1);
}

.btn-outline-primary{
    color: var(--level1);
    border-color: var(--level1) !important;
    background: #ffffff;
}
.btn-outline-primary:hover{
    border-color: var(--level1) !important; 
    background: #ffffff;
	color: #000000;
}

.btn-link{
    color: var(--level1);
    /* text-decoration: underline;  */
    padding-left: 0px;
    padding-right: 0px;
} 

.btn-group-toggle label{
    font-weight: normal !important
}
.btn-group-toggle label.active{
    font-weight: bold !important
}

.scroll-to-top{
    position: fixed;
    background: #fff;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    z-index: 1040;
    left: calc(85% + 55px);
    bottom: 100px;
} 


@media (max-width: 576px) {
    .btn{
        width: 100% !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
}
@media (max-width: 768px) {
    .btn{}
}
@media (max-width: 992px) {
    .btn{}
}


.action-circle-btn{ 
    border: 1px solid #343a40;
    width: 25px;
    height: 25px;
    text-align: center;
    border-radius: 50%;
    background: #fff;
}