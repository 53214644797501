
.loader{
    position: absolute;
    background: #fff;
    width: 100%;
    height: calc(100vh - 130px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.loader > div{
	width: 3em;
	height: 3em;
}

.inbody-loader{ 
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.wholebody-loader{
    position: fixed;
    z-index: 2000;
    left: 0;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.inside-loader{
    height: 100%;
    position: absolute;
    z-index: 5000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.7);
    right: 0;
    left: 0;
}

.inside-loader::after {
    width: 100%;
    height: 100%; 
    z-index: 3000;
    position: absolute;
    content: '';
}