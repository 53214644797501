/* ASPECT RATIOs */

.ar32{ 
    overflow: hidden;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #00010E;
    padding-top: 66.66%;
    box-sizing: content-box;
	position: relative; 
    width: 100%;

    img {
        height: 100% !important;
        position: absolute;
        top: 0;
        width: unset !important;
    }

}