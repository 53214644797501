
/* MODAL */
.modal-sm {
    max-width: 400px;
}
.modal .modal-content,
.modal-sm .modal-content{
	background: var(--level5) !important;
}

.modal-meduim{
    max-width: 600px;
}
.modal-large{
    max-width: 800px;
}
.modal-large{
    max-width: 1000px;
}
.modal-content{
    padding-bottom: 30px !important;
}
.modal-body,
.modal-footer,
.modal-header{
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-top: 30px !important;
    padding-bottom: 0px !important;
}
.modal-title{
    font-size: 20px;
    font-weight: bold; 
    padding-bottom: 30px !important;
}
.modal-title-sub,
.modal-text{
    font-size: 16px;
    font-weight: normal; 
}

.fullScreenModal{
    .modal-dialog{
        height: calc(100% - 0px) !important;
        margin-top: 0px !important;
    }
}

.media-item-view-modal{
    &{
        z-index: 2050 !important;
    }
    .modal-content{
        background: transparent !important;
    }
    *{
        color: #fff
    }
}
