@import '~bootstrap/scss/bootstrap.scss';
@import '~animate.css';
@import '~font-awesome/css/font-awesome.css';
@import '~@mdi/font/css/materialdesignicons.css';
@import './_header.scss';
@import './_persistent_header.scss';
@import './_loader.scss';
@import './_specialty.scss';
@import './_surgeon_cards.scss';
@import './_cropper.scss';
@import './_page_with_side.scss';
@import './_table.scss';
@import './_popovers.scss';
@import './_error.scss';
@import './_rc_modal.scss';
@import './_swiper.scss';
@import './_input.scss';
@import './_media.scss';
@import './_modal.scss';
@import './_buttons.scss';
@import './_validation.scss';
@import './_style.scss';
@import './_stripe.scss';


@for $i from 0 through 10 {
	.opacity-#{$i}{
		opacity: $i/10 !important; 
	}
	.z-index-#{$i}k{
		z-index: $i * 1000 !important;
	}
}

@for $i from 0 through 60 {
	/* PADDING */
	.p#{$i}{padding: 1px * $i !important}
	.px#{$i}{padding-left: 1px * $i !important;padding-right: 1px * $i !important;}
	.py#{$i}{padding-top: 1px * $i !important;padding-bottom: 1px * $i !important;}
	.pt#{$i}{padding-top: 1px * $i !important;}
	.pb#{$i}{padding-bottom: 1px * $i !important;}
	.pr#{$i}{padding-right: 1px * $i !important;}
	.pl#{$i}{padding-left: 1px * $i !important;}


	/* MARGINS */	
	.m#{$i}{margin: 1px * $i !important;}
	.mx#{$i}{margin-left: 1px * $i;margin-right: 1px * $i !important;}
	.my#{$i}{margin-top: 1px * $i;margin-bottom: 1px * $i !important;}
	.mt#{$i}{margin-top: 1px * $i !important;}
	.mb#{$i}{margin-bottom: 1px * $i !important;}
	.mr#{$i}{margin-right: 1px * $i !important;}
	.ml#{$i}{margin-left: 1px * $i !important;}

	.fs#{$i}{font-size: 1px * $i !important;}
} 

@for $i from 0 through 1300 {
	.min-table-width-#{$i}{
		min-width: 1px * $i !important;
	}
}

@import './_responsive.scss';

.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		 -khtml-user-select: none; /* Konqueror HTML */
			 -moz-user-select: none; /* Firefox */
				-ms-user-select: none; /* Internet Explorer/Edge */
						user-select: none; /* Non-prefixed version, currently
																	supported by Chrome and Opera */
 }

@import '/Refined.scss';

// -- vars
$bg-color: #34495e;
$default-size: 5em;
$label-font-size: $default-size / 4;
$label-font-size-redo: $default-size * 4;

// -- mixins
@mixin size($width, $height) {
  height: $height;
  width: $width;
}

@mixin draw-progress($progress, $color) {
  .pie {
    .half-circle {
      border-color: $color;
    }

    .left-side {
      transform: rotate($progress * 3.6deg);
    }

    @if $progress <= 50 {
      .right-side {
        display: none;
      }
    } @else {
      clip: rect(auto, auto, auto, auto);

      .right-side {
        transform: rotate(180deg);
      }
    }
  }
}

@mixin draw-progress--solid($progress, $color, $bg-color) {
  background: linear-gradient(to right, $color 50%, $bg-color 50%);
  
  &:before {
    @if $progress <= 50 {
      background: $bg-color;
      transform: rotate((100 - (50 - $progress)) / 100 * 360deg * -1);
    } @else {
      background: $color;
      transform: rotate((100 - $progress) / 100 * 360deg);
    }
  }
}
 

.set-size {
  font-size: 10em;
}

.charts-container {
  &:after {
    clear: both;
    content: '';
    display: table;
  }
}

.pie-wrapper {
  @include size($default-size, $default-size);
  float: left;
  margin: 15px;
  position: relative;
    display: flex;
    justify-content: center;
  
  &:nth-child(3n + 1) {
    clear: both;
  }

  .pie {
    @include size(100%, 100%);
    clip: rect(0, $default-size, $default-size, $default-size / 2);
    left: 0;
    position: absolute;
    top: 0;

    .half-circle {
      @include size(100%, 100%);
      border: ($default-size / 10) solid #3498db;
      border-radius: 50%;
      clip: rect(0, $default-size / 2, $default-size, 0);
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  .label {
    background: $card-bg;
    border-radius: 50%;
    bottom: $label-font-size-redo / 10;
    color: $text-color;
    cursor: default;
    display: block; 
    font-size: 1.25em;
    // left: 1.4em;
    line-height: 3.7em;
    position: absolute;
    // right: 1.5em;
    text-align: center;
    top: 0px;

    .smaller {
      color: #bdc3c7;
      font-size: .45em;
      padding-bottom: 20px;
      vertical-align: super;
    }
  }

  .shadow {
    @include size(100%, 100%);
    border: $default-size / 10 solid #bdc3c7;
    border-radius: 50%;
  }

  &.style-2 {
    .label {
      background: none;
      color: $text-color;

      .smaller {
        color: $text-color;
      }
    }
  }
  
  @for $i from 0 through 100 { 
    &.progress-#{$i} {
        @include draw-progress($i, $primary-medium);
    }
  } 
}

.pie-wrapper--solid {
  border-radius: 50%;
  overflow: hidden;

  &:before {
    border-radius: 0 100% 100% 0 / 50%;
    content: '';
    display: block;
    height: 100%;
    margin-left: 50%;
    transform-origin: left;
  }
  
  .label {
    background: transparent;
  }
  
    @for $i from 0 through 100 {
	/* PADDING */ 
  &.progress-#{$i} {
    @include draw-progress--solid($i, #3498db, $bg-color);
  }
  }
   
}
