.specialty-card{
	width: 120px;
	height: 120px;
	font-size: 16px;
}
.specialty-card:hover{
	cursor: pointer;
}

.specialty-card,
.specialty-card .card{
	transition: .2s ease-in-out;
}

.specialty-card .card{
	border-radius: 4px;
	border: 0px;
	background: var(--level4);
    height: 100%;
}
.specialty-card .card-body{
	padding: 0px;
}
.card-subtitle{
	font-size: 14px;
}
.specialty-card img{
	width: 50px;
	height: 50px;
	margin-right: 20px;
	margin-top: 20px; 
	margin-left: 20px;
}


.specialty-card{
	transition: .1s ease-in-out;
}
.specialty-card.selected img,
.specialty-card:hover img{
	filter: brightness(0) invert(1);
}

.specialty-card.selected .card,
.specialty-card:hover .card{
	background: var(--level1);
	color: #ffffff;
}

.surgeon-card .specialty-pill,
.specialty-pill{
	height: 25px;
	font-size: 12px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
}