/* 
    BUTTONS

    .cta || .cta-lg
    
        -primary-cta
        -secondary-cta
        -teriary-cta

        -square btn
*/

.cta{
    &.disabled{
        opacity: 0.5;
        pointer-events: none !important;
    }
    &{
        position: relative;
        display: flex;
        align-items: center;
        margin-left: 12px;
        margin-right: 12px;
        font-family: $lato-font-family;
    }
    &:not(.cta-tertiary){
        padding: 9px 30px;
        border-radius: 3px;
        transition: 0.2s ease-in-out; 
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.5px;
    }
    &:not(.cta-tertiary):hover{
        cursor: pointer;
        box-shadow: $card-shadow;
        transition: 0.2s ease-in-out;  
    }
    &:not(.cta-tertiary):not(.cta-lg){
        justify-content: center;
    }
    &:first-child{
        margin-left: 0px;
        margin-right: 12px;
    }
    &:last-child:not(:first-child){
        margin-left: 12px;
        margin-right: 0px;
    }
    &.cta-lg{
        &{
            padding: 12px !important;
            justify-content: start;
            text-align: left;
            min-width: 180px;
        }
        &:first-child{ 
            margin-left: 0px;
            margin-right: 12px;
        }
    }
    img{
        width: 50px;
        height: 50px;
        margin-right: 12px;  
    }
    @media screen and (max-width: 768px){ 
        &.expand{
            width: 100% !important;
        }
    }
}

$primary-bg: $accent-dark;
$primary-hover-bg: $accent-dark;
$primary-img-bg: $white;
$primary-text-color: $white;
$primary-outline-text-color: $accent-dark;
$primary-border-color: $accent-dark;
$primary-outline-border-color: $accent-dark;
$primary-outline-hover-text-color: $white;

$secondary-bg: $primary-medium;
$secondary-hover-bg: $primary-medium;
$secondary-img-bg: $white;
$secondary-text-color: $white;
$secondary-outline-text-color: $primary-medium;
$secondary-border-color: $primary-medium;
$secondary-outline-border-color: $primary-medium;
$secondary-outline-hover-text-color: $white;

$teriary-text-color: $secondary-medium;

.primary-cta, .cta-primary{
    &, & a{
        background: $primary-bg;
        background-color: $primary-bg;
        border: 1px solid $primary-border-color;
        color: $primary-text-color !important;
    }
    & a:hover{
         text-decoration: none !important;
    }
    img{
        background: $white;
    }
    &:hover, &.active{
        background: $primary-hover-bg;
        background-color: $primary-hover-bg;
        text-decoration: none !important;
    }
    
}

.cta-primary-outline{ 
    background: transparent;
    background-color: transparent;
    border: 1px solid $primary-outline-border-color;
    color: $primary-outline-text-color !important; 
    &:hover, &.active{
        background: $primary-hover-bg;
        background-color: $primary-hover-bg; 
        color: $primary-outline-hover-text-color !important; 
    }
}
/* 
.secondary-cta, .cta-secondary{
    &{
        background: $secondary-bg;
        background-color: $secondary-bg;
        border: 1px solid $secondary-border-color;
        color: $secondary-text-color !important;
    }
    img{
        background: $white;
    }
    &:hover, &.active{
        background: $secondary-hover-bg;
        background-color: $secondary-hover-bg; 
    }
    
} */

.secondary-cta, .cta-secondary, .cta-secondary-outline{ 
    &, & a{
        background: $white;
        background-color: $white;
        border: 1px solid $secondary-outline-border-color;
        color: $secondary-outline-text-color !important; 
    }
    & a:hover{
         text-decoration: none !important;
    }
    &:hover, &.active{
        background: $secondary-hover-bg;
        background-color: $secondary-hover-bg; 
        color: $secondary-outline-hover-text-color !important;
        text-decoration: none !important;
    }
}

 
.cta-tertiary{ 
    color: $teriary-text-color !important;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.5px;
    transition: .2s ease-in-out;
    align-self: center;
    padding: 0px;
    &:hover, &.active{
        cursor: pointer;
        text-decoration: underline;
        transition: .2s ease-in-out;
        box-shadow: 0px 0px 0px;
    }
    img{
        background: $white;
    }
}

.square-btn{
    width: 120px;
    height: 120px;
}