/*NAMING FORMAT
[category] = .(class) | #(id)


[category]propname-elementname

[category]propname-elementname|event[optional]-elementname[optional]

*/

:root{ 
	--level1:#4a4a4a;
	--level2:#9b9b9b;
	--level3:#a2a2a2;
	--level4:#d8d8d8;
	--level5:#f4f4f4;
	--level6:#f9f9f9;
	--level9:#ffffff;
	

	--h1_font_size: 42px;
	--h2_font_size: 24px;
	--h3_font_size: 20px;
	--h4_font_size: 16px;
	--h5_font_size: 14px;
	--body_font_size: 16px;
	--sub_body_font_size: 14px; 

	--bold_font_weight: bold;
	--med_font_weight: 600;
	--regular_font_weight: 400;
	--light_font_weight: 300;
}
html, body,#root, main{
	height: 100%;
	scroll-behavior: smooth;
}
*:not(.fa):not(.mdi) {
	font-family: 'Open Sans', sans-serif; 
}
body{
	background: var(--level6);
	overflow-x: hidden;
}
h1{
	font-size: 42px;
}
h2{
	font-size:38px;
}
h3{
	font-size: 36px;
}
h4{
	font-size: 28px;
}
h5{
	font-size: 20px;
}
h6{
	font-size: 16px
}
p{
	font-size: 16px;
}

@for $i from 0 through 60 {	
	/* PADDING */
	.p#{$i}{padding: 1px * $i !important}
	.px#{$i}{padding-left: 1px * $i !important;padding-right: 1px * $i !important;}
	.py#{$i}{padding-top: 1px * $i !important;padding-bottom: 1px * $i !important;}
	.pt#{$i}{padding-top: 1px * $i !important;}
	.pb#{$i}{padding-bottom: 1px * $i !important;}
	.pr#{$i}{padding-right: 1px * $i !important;}
	.pl#{$i}{padding-left: 1px * $i !important;}


	/* MARGINS */	
	.m#{$i}{margin: 1px * $i !important;}
	.mx#{$i}{margin-left: 1px * $i;margin-right: 1px * $i !important;}
	.my#{$i}{margin-top: 1px * $i;margin-bottom: 1px * $i !important;}
	.mt#{$i}{margin-top: 1px * $i !important;}
	.mb#{$i}{margin-bottom: 1px * $i !important;}
	.mr#{$i}{margin-right: 1px * $i !important;}
	.ml#{$i}{margin-left: 1px * $i !important;}

	.fs#{$i}{font-size: 1px * $i !important;}
} 


/* FONT SIZES AND WEIGHTS */

label,
p{
	font-size: var(--body_font_size) !important;
} 

thead > tr > td,
thead > tr > th,
tbody > tr > th,
tbody > tr > td,
table *{
	font-size: var(--sub_body_font_size) !important;
} 
th i{
    color: white;
}
.h1bold,
.h1med,
.h1regular,
.h1light{
	font-size: var(--h1_font_size) !important;
}

.h2bold,
.h2med,
.h2regular,
.h2light{
	font-size: var(--h2_font_size) !important;
}

.h3bold,
.h3med,
.h3regular,
.h3light{
	font-size: var(--h3_font_size) !important;
}

.h4bold,
.h4med,
.h4regular,
.h4light{
	font-size: var(--h4_font_size) !important;
}

.h5bold,
.h5med,
.h5regular,
.h5light{
	font-size: var(--h5_font_size) !important;
}

.bodybold,
.bodymed,
.bodyregular,
.bodylight{ 
	font-size: var(--body_font_size) !important;
}

.h1bold,
.h2bold,
.h3bold,
.h4bold,
.h5bold,
.bodybold{
	font-weight: var(--bold_font_weight) !important;
}
.h1med,
.h2med,
.h3med,
.h4med,
.h5med,
.bodymed,
label{
	font-weight: var(--med_font_weight) !important;
}
.h1regular,
.h2regular,
.h3regular,
.h4regular,
.h5regular,
.bodyregular{
	font-weight: var(--regular_font_weight) !important;
}
.h1light,
.h2light,
.h3light,
.h4light,
.h5light,
.bodylight{
	font-weight: var(--light_font_weight) !important;
}

/* END OF FONT SIZES AND WEIGHTS */


.title, .page-title{
	margin-bottom: 30px;
}
.sub-title, .page-sub-title{
	margin-bottom: 30px; 
}
.text{
	font-size: 16px !important;
	margin-bottom: 30px;
}

main{
	min-height: calc(100vh - 130px);
	padding-top: 30px !important;
	padding-bottom: 60px !important;
}

footer{
    height: 70px;
}
.no-nav main{
    min-height: calc(100vh - 70px);
}
.container{
    padding-right: 15px;
    padding-left: 15px;
}
.row-orig{
    margin-right: -15px;
    margin-left: -15px;   
}
/* font weights */
.fw-100{
	font-weight: 100 !important;
}

/*ROW, COL Custom*/

.row { 
    margin-right: -20px;
    margin-left: -20px;
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto{ 
    padding-right: 20px;
    padding-left: 20px;
}

@for $i from 0 through 1200 {	
	/* HEIGHTS */
	.h#{$i}{height:1px * $i !important;} 
	.mh#{$i}{min-height:1px * $i !important;} 
	 
	/* WIDTHS */
	.w#{$i}{width:1px * $i !important;} 
	.mw#{$i}{min-width:1px * $i !important;} 
	.max-w#{$i}{max-width:1px * $i !important;}  
	.max-h#{$i}{max-height:1px * $i !important;}  
	.max-h-#{$i}{max-height:1% * $i !important;}  
}

/*CARD Custom*/
.card-body{
	padding: 20px;
}
.bg-black{
	background: #000 !important;
}
.bg-header{
	background: var(--level2);
	transition: .1s ease-in-out;
}
.bg-navheader{	
	background: var(--level2);
	transition: .01s ease-in-out;
}

.bg-navheader:hover{ 
}
.bg-navheader .nav-link{
	color: var(--level1)
}
.bg-navheader:hover,
.bg-navheader.show,
.bg-navheader:hover .nav-link{
	background: var(--level1);
	color: var(--level5);
} 
.text-navheader:hover{
	color: var(--level1);
}
.text-navheader{
	color: var(--level5);
}

.bg-navdropdown:hover,
.bg-navdropdown.show,
.bg-navdropdown,
.bg-mega-menu{
	background: var(--level4)
}

.hover-dropdown:hover > *,
.hover-dropdown:hover .dropdown-menu{
	display: block;
	color: var(--level5) !important;
}
/*.bg-mega-header*/



/*OVERFLOWS*/
.of-hidden{
	overflow: hidden;
}
.of-auto{
	overflow: auto;
}
.ofx-auto{
	overflow-x: auto;
	overflow-y: hidden;
}
.ofy-auto{
	overflow-y: auto;
	overflow-x: hidden;
}

.c-pointer:hover,
.cursor-pointer:hover{
	cursor: pointer;
}

.c-grab:hover,
.cursor-grab:hover{
	cursor: grab;
}


/* anchor */
main a{
    font-weight: bold;
	color: var(--level1);
    text-decoration: none; 
	
}
main a:hover{
    color: black;
    text-decoration: underline;
}

main a:hover *{
	text-decoration: none !important;
}
a.no-underline:hover{
	text-decoration: none !important;
}

/* button */
.btn{
	font-weight: bold;
}
.btn-element{
	min-width: 120px;
}

.btn-primary{
	background: var(--level1);
}
.btn-outline-dark{
	background: #ffffff;
}
.btn-outline-dark:hover{
	background: #ffffff;
	cursor: pointer;
	color: #000000;
}

/* width */
::-webkit-scrollbar {
	width: 5px;
}

/* Track */
::-webkit-scrollbar-track {		
	background-color: #B7B2B2;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #4a4a4a; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #4a4a4a; 
}

/*BTNS*/

/* LABELS */
label {
    font-size: 16px;
    font-weight: 600;
}
h1,h2,h3,h4,h5,h6{
    font-weight: 600;
}

.info > div{
    width: 25px;
    height: 25px;
    border: 1px solid;
    border-radius: 50%; 
    text-align: center;
}

.medical-inventory-card:hover,
.medical-inventory-card:hover p{
	text-decoration: none !important;
}

.toolbar-options {
    border-radius: 1px;
    background-color: #E8E8E8;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    min-height: 44px;
}

.react-tagsinput{
	min-height: 100px;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    border-bottom: 2px solid #000 !important;
}


.list-group-item.active{

    border-color: rgba(0,0,0,.125) !important;
}
.list-group-item.active a{
    font-weight: bold !important;
    text-decoration: underline !important;
    border-color: unset !important;
}

#bp_caption .bp-x{
	display: none !important;
}

.work-break-all{
	word-break: break-all !important;
}
.change_media_label{
	opacity: 0;
	transition: .2s ease-in-out;
}
.preview-media-section:hover .change_media_label{
	opacity: 1;
	transition: .2s ease-in-out;
}
.change_media_label{
    position: absolute;
    background: #000000c7;
    height: 100%;
    width: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}


/* COLLAPSE */
.expanded-state{
	display: block;
	transition: .5s ease-in-out;
}
.collapsed-state{
	display: none;
	transition: .5s ease-in-out;
}
.collapsed{ 
	.expanded-state{
		display: none;
	}
	.collapsed-state{
		display: block;
	}
}

.bg-primaryblack{
    background: #00010E;
}

.image-div{
    overflow: hidden;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #00010E;
    padding-top: 66.66%;
    box-sizing: content-box;
	position: relative; 
	width: 100%;
}
.image-div > img {
    height: 100% !important;
    position: absolute;
    top: 0;
	width: unset !important;
	left: 0;
}








/* 

    COLORS
    -primary-light
    -primary-medium
    -primary-dark
    
    -secondary-light
    -secondary-medium
    -secondary-dark
    
    -accent-light
    -accent-medium
    -accent-dark
    
    -card-background

    -grey-background
    
    -text-color

            -green
            -light-green
            -dark-green
            -purple
            -light-purple
            -dark-purple
            -blue
            -light-blue
            -dark-blue
            -yellow
            -light-yellow
            -dark-yellow
            -red
            -light-red
            -dark-red


*/
$white: #FFFFFF;
$light-bg: #FFFFFF;
$black: #000000;
$black-bg: #000000;

$primary-light: #F0EFFF;
$primary-medium: #3451A9;
$primary-dark: #3451A9;

$secondary-light: #9013FE; 
$secondary-medium: #5C5AC8; //hover over colors, selected
$secondary-dark: #5C5AC8;
$accent-light: rgb(7, 10, 9);
$accent-medium: #08CE92;
$accent-dark: #208362;

$gray-light:#D8D8D8;
$gray-medium:#979797;
$gray-dark:#979797;

$card-background: #FFFFFF;
$grey-background: #F9F9F9;
$text-color-light: #FFFFFF;
$text-color: #4A4A4A;
$text-color-dark: #000222;

$violet-light: #7674D6;

$success: #0E7505;
$warning: #F5A623;
$information: #4A90E2;
$error: #D0021B;


$primary-light: #F2F5FF;
$primary-medium: #3451A8;
$primary-dark: #102040;

$secondary-light: #F0EFFF;
$secondary-medium: #5C5AC8;
$secondary-dark: #22216A;

$accent-light: #BBFFEA;
$accent-medium: #08CE92;
$accent-dark: #208362;

$card-bg: #FFFFFF;
$grey-bg: #F9F9F9;

$body-bg: #f9f9f9;
$editor-bg: #303D72;

$holy-gradient:	linear-gradient(359.91deg, #08CE92 0%, #5C5AC8 35.79%, #3451A9 100%);;

*{
    color: $text-color;
}


a, .link{
    color: $primary-medium;
}
a:hover, .link:hover{
    cursor: pointer;
    text-decoration: underline !important;
}

h1,h2,h3,h4,h5,h6{
    color: $text-color-dark;
}

.success,.valid{
    color: $success !important;
}
.error,.invalid{
    color: $error !important;
}
.information{
    color: $information !important;
}
.warning{
    color: $warning !important;
}





$popover-item-card-bg: $white;
$popover-item-card-shadow: 0 2px 10px 0 rgba(171,171,171,0.5);
$popover-item-card-title-color: $text-color-dark; 
$popover-item-card-description-color: $text-color; 











.contenteditable{ 
    padding: 0.375rem 0.75rem;
	min-height: 112px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  
/* TIPPY */

.tippy-tooltip, .tippy-backdrop{
    background: transparent !important;
    background-color: transparent !important;
}

.tippy-tooltip{
    position: relative;
    padding: 0px;
}
.tippy-popper[x-placement^=right] .tippy-arrow{
    border-right: 20px solid #333;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    border-right-color: #FFFFFF !important; 
    filter: drop-shadow(-5px 0px 3px rgba(171,171,171,0.3));
    left: -19px;
}


.specialty-span, 
.specialty-pill,
.tags-pill {
    font-size: 16px; 
    padding: 0px 12px;
    background: $primary-medium;
    border-radius: 17px;
    margin-right: 12px;
    color: $text-color-light !important;
    float: left;
    margin-top: 12px;
    height: 32px;
    display: flex;
    align-items: center;
}
.tags-pill{
    margin-top: 8px; 
}



    /* POPOVER */
    .popover{
        padding: 0px;
        border: 0px; 
    }
    .popover-body{
        padding: 0px !important;
    }
    .popover-item-card{
        background: $popover-item-card-bg;
        padding: 8px;
        box-shadow: $popover-item-card-shadow;
        border-radius: 4px;
        text-align: left;
        .item-img{ 
            margin-bottom: 8px;
            img{
                width: 100%;
            }
        }
        .title{
            font-weight: bold;
            margin-bottom: 8px;
            color: $popover-item-card-title-color; 
            font-size: 20px !important;
            text-align: left;
        } 
        .sub-title{
            font-size: 16px !important;
            text-align: left;
        }
        .description{
            margin-bottom: 8px;
            color: $popover-item-card-description-color; 
            font-size: 16px !important;
            text-align: left;
            max-height: 115px;
            overflow: hidden;    
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            p{
                margin-bottom: 12px;
                &:last-child{
                    margin-bottom: 0px;
                }
            }
        }
        .tags{
            max-height: 125px;
            overflow: hidden;
        }
    }
	
	
	.has-action{
		font-weight: bold;
	}