
/* Popover */
.popover {
    max-width: 530px !important;
    min-width: 530px !important;
}

.popover img{
    width: 150px;
    height: 150px;
}
.popover-body{
	padding: 10px !important;
}

.popover{ pointer-events:none; }

.popover-header { 
    padding-bottom: 0px !important;
    border: 0px !important;
    background: #fff !important;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
    margin-left: 1rem;
}
.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
    margin-right: 1rem;
}