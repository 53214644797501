.light-theme{
    
    @import '/light_theme/_colors.scss';
    @import '/light_theme/_variables.scss';
    @import '/light_theme/_typography.scss';
    @import '/light_theme/_aspect_ratio.scss';
    @import '/_line_form.scss';

    /* LIGHT theme */
    background: $body-bg;
    
    @import '/light_theme/_header.scss';
    @import '/light_theme/_buttons.scss';
    @import '/light_theme/_components.scss';
    @import '/light_theme/_containers.scss';
    // @import '/light_theme/_forms.scss';
    // @import '/light_theme/_popover.scss';
        
    @import '/light_theme/_modals.scss';


    .popover{
        padding: 0px;
        border: 0px;  
    }
    .popover-body{
        padding: 0px !important;
    }
}
