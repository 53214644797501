.invalid-feedback{
    font-size: 100%;
}

.was-validated .form-control:invalid, .form-control.is-invalid{
    background-image: none !important;
}

.non-rounded-input > *{
    border-radius: 0px !important;
}