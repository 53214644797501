.notifier-div{
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    border-radius: 1px;
    height: 44px;
    background-color: #FFFFFF;
    text-align: center !important
}

.notifier-div.success{
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 
                10px 0px 0px 0px #0E7505 inset;
    color: #0E7505 !important;
} 
.notifier-div.error{
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 
                10px 0px 0px 0px #D0021B inset;
    color: #D0021B !important;
} 
.notifier-div.warning{
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 
                10px 0px 0px 0px #F5A623 inset;
    color: #F5A623 !important;
} 
.notifier-div.info{
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 
                10px 0px 0px 0px #4A90E2 inset;
    color: #4A90E2 !important;
} 

.close-notification{
    /* position: absolute;
    right: 30px;
    top: 10px; */
    margin-right: 10px;
    margin-left: -26px;

}