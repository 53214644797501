

/* RC THUMBNAIL */
.rc-dialog-title {
	font-size: var(--h3_font_size) !important;
	font-weight: var(--bold_font_weight) !important;
}
.rc-thumbnail-preview canvas{ 
	border: 1px solid var(--level2) !important;
}
.rc-dialog-body{
	padding-left: 0px !important;
	padding-right: 0px !important;
	padding-top: 30px !important;
	padding-bottom: 30px !important;
}
.rc-thumbnail-preview h4{
	font-size: 18px !important;
}

.rc-dialog-header,
.rc-dialog-footer{
	border: 0px !important;
	padding: 0px 0px !important;
}
.rc-dialog-header{
	padding-bottom: 0px !important;
}
.rc-dialog-footer{
	padding-top: 0px !important; 
}
.rc-scaller {
    display: inline-block !important;
    text-align: center !important; 
    width: calc(100% - 180px) !important;
}
.rc-thumbnail-preview { 
    padding: 38px 0px !important;
    background-color: white !important;
		padding-left: 33px !important;
		margin-bottom: 30px !important;
}
.rc-thumbnail-preview p{
	display: none !important;
}
.rc-dialog-wrap  > .rc-dialog{
	width: 95% !important; 
	margin: 30px auto !important;
} 

.rc-dialog-content{
	padding: 30px 30px !important;
	margin: 0 auto !important;
}
.rc-dialog-footer{
	display: flex !important;
	justify-content: space-between;
}
.rc-scaller{
	margin: 0px !important;
}
.rc-btn-primary,
.rc-btn-primary:hover{
	color: #fff !important; 
	cursor: pointer !important; 
	background-color: #343a40 !important; 
	border-color: #343a40 !important; 
	border-radius: 3px !important; 
	order: 1 !important; 
}
.rc-btn-ghost{
	float: right !important;
	background: #ffffff !important;
	cursor: pointer !important;
	color: #000000 !important;
	border-color: #343a40 !important;
	border-radius: 3px !important;
	order: 2 !important;
}

.rc-dialog-close{
	top:30px !important;
}

.rc-scaller button > span{
	  display: inline-block !important;
    font: normal normal normal 14px/1 FontAwesome !important;
    font-size: inherit !important;
    text-rendering: auto !important;
    -webkit-font-smoothing: antialiased;
}

.rc-scaller button:first-child > span:before{ 
		content: "\F010" !important;
		font-family: inherit !important;
}
.rc-scaller button:last-child > span:before {
		content: "\F00E" !important;
		font-family: inherit !important;
}


.rc-dialog-header,
.rc-dialog-footer,
.rc-dialog-content{ 
	border: 0px !important;
	width: 100% !important; 
} 

@media (max-width: 576px) and (min-width: 200px) {
	.rc-dialog-footer{
		&{
			flex-direction: column;
		}
		.rc-btn.rc-btn-ghost{
			margin-top: 20px !important;
		}
		
		.rc-scaller{
			width: 100% !important;
			margin-bottom: 20px !important;
		}
		
		.rc-scaller .rc-slider {
			width: 130px;
		}
	}
}
@media (max-width: 767px) and (min-width: 200px) {
	.rc-thumbnail-preview{
		display: none !important;
	}
	.rc-cropper-wrapper{
		padding-right: 0px !important;
	}
}
@media (min-width: 768px), (max-width: 992px),(min-width: 992px) {
	.rc-dialog-content{  
		width: 90% !important; 
	}
}
@media (min-width: 1200px) {
	.rc-dialog-content{  
		width: 65% !important; 
	}
}
