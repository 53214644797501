
 /*INPUT VALIDATION*/
.form-control.is-warned, .was-validated .form-control:warned{
    padding: .375rem .75rem;
    background-image: unset;
}
.form-control.is-invalid, .was-validated .form-control:invalid{
    padding: .375rem .75rem;
    background-image: unset;
}
.form-control.is-valid, .was-validated .form-control:valid{
    padding: .375rem .75rem;
    background-image: unset;
}
.form-control.is-warned, .is-warned{
    border-color: #F5A623;
}
.error-state-icon{
    display: inline-block;
    width: 100%; 
}
.error-state-icon i{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 20px !important; 
    margin-right: 5px;
    line-height: 1em;
    bottom: -3px;
    position: relative;
}

.positive{
    color: #0E7505;
}
.error{
    color: #D0021B;
}
.warning{
    color: #F5A623;
}
.info{
    color: #4A90E2;
}
.info-o{
    color: #4A90E2;
}

.td-notifier *,
.td-notifier{
    text-align: center;
}
.td-notifier {
    text-align: center;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    background: #ffffff;
    box-shadow: 0px 3px 3px 0px #818181, 8px 0px 0px 0px green inset;
}

.bd-callout-positive{ border-left-color: #0E7505 !important;}
.bd-callout-error{ border-left-color: #D0021B !important;}
.bd-callout-warning{ border-left-color: #F5A623 !important;}
.bd-callout-info{ border-left-color: #4A90E2 !important;}

.bd-callout{ 
    padding: 13px;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    border-left: 10px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    margin-bottom: 20px !important;
    z-index: 2;
    background: #fff;
    text-align: center !important;
    font-weight: var(--med_font_weight);
}